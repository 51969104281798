import { createBrowserHistory } from 'history';

import { logPageView } from '/helpers/googleAnalytics';
import { log } from '/helpers/log';

export const history = createBrowserHistory();

const unlisten = history.listen((location, action) => {
  log('router', {
    action,
    path: location.pathname,
    state: location.state,
  });
});

if (process.env.NODE_ENV === 'production') {
  unlisten();
  history.listen((location) => {
    logPageView(location.pathname);
  });
}
