import { Formatter } from '@playq/irt';
import { TimePrecision } from '@playq/octopus-common';

import { timeUnitPrecisionMillisecondValues } from '/constants';

// Validation for date range length, for example min acceptable length may be 3 days
export const isValidDateRangeLength = (
  startDate: string,
  endDate: string,
  minimalDaysRange: number,
  utc?: boolean
): boolean => {
  const startDateTime = Formatter.readDateTime(startDate, utc).getTime();
  const endDateTime = Formatter.readDateTime(endDate, utc).getTime();
  const minDataRangeValue = timeUnitPrecisionMillisecondValues[TimePrecision.Day] * minimalDaysRange;

  return endDateTime - startDateTime >= minDataRangeValue - timeUnitPrecisionMillisecondValues[TimePrecision.Minute];
};

export const initialDates = (minDaysRange = 3): { initialStartDate: Date; initialEndDate: Date } => {
  const initialStartDate = new Date(new Date().setHours(0, 0, 0, 0));
  const initialEndDate = new Date(initialStartDate.getTime());

  initialEndDate.setDate(initialStartDate.getDate() + minDaysRange - 1);
  initialEndDate.setHours(23, 59, 0, 0);

  initialStartDate.setSeconds(0, 0);
  initialEndDate.setSeconds(0, 0);

  return { initialStartDate, initialEndDate };
};
