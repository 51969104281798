export function formatSize(bytes: number): string {
  const bik = 1000;

  if (bytes < bik) {
    return `${bytes} B`;
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= bik;
    u += 1;
  } while (bytes >= bik);

  return bytes.toFixed(3) + units[u];
}
