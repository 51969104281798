import { FilterHelpers, TextFilter } from '@playq/octopus-common';
import { OffsetLimit } from '@playq/services-shared';

import { ITableQuery, ITableColumnSort } from '/shared/Table/interfaces';
import { IBaseSort, IQuery, IQuerySerialized, QueryFilter, QueryFilterSerialized } from '/common/models/interfaces';
import { entitiesLimit, iteratorMaxLimit } from '/constants';

import { ComponentType } from './types';

export class QueryHelpers {
  static getInitValue = <S extends string, SC = IBaseSort<S>>(): IQuery<S, SC> => {
    return {
      iterator: QueryHelpers.getInitIterator(),
      sortBy: [],
      filterBy: {},
    };
  };

  static getInitTableQueryValue = <S extends string>(): ITableQuery<S> => ({
    filterBy: {},
    page: 0,
    sortBy: [] as ITableColumnSort<S>[],
    rowsPerPage: 10,
  });

  static getMaxValue = <S extends string, SC = IBaseSort<S>>(): IQuery<S, SC> => {
    return {
      iterator: QueryHelpers.getMaxIterator(),
      sortBy: [],
      filterBy: {},
    };
  };

  static serialize = <S extends string, SC extends IBaseSort<S>>(q: IQuery<S, SC>): IQuerySerialized => {
    return {
      iterator: q.iterator.serialize(),
      // @ts-expect-error ts can't recognize serialize method. should be created a new SortClass later
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
      sortBy: q.sortBy.map((s: SC) => s.serialize()),
      filterBy: QueryHelpers.serializeFilterBy(q.filterBy),
    };
  };

  static deserialize = <S extends string, SC extends IBaseSort<S>>(
    qs: IQuerySerialized,
    SortClass?: new (bs: IBaseSort<string>) => SC
  ): IQuery<S, SC> => {
    return {
      iterator: new OffsetLimit(qs.iterator),
      sortBy: SortClass ? qs.sortBy.map((s: IBaseSort<string>) => new SortClass(s)) : [],
      filterBy: QueryHelpers.deserializeFilterBy(qs.filterBy),
    };
  };

  static serializeFilterBy = (filterBy: QueryFilter) => {
    return Object.keys(filterBy).reduce((acc: QueryFilterSerialized, next: string) => {
      acc[next] = FilterHelpers.serialize(filterBy[next]);
      return acc;
    }, {});
  };

  static deserializeFilterBy = (filterBy: QueryFilterSerialized) => {
    return Object.keys(filterBy).reduce((acc: QueryFilter, next: string) => {
      acc[next] = FilterHelpers.deserialize(filterBy[next]);
      return acc;
    }, {});
  };

  static getInitIterator = () => new OffsetLimit({ limit: entitiesLimit, offset: 0 });

  static getMaxIterator = () => new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 });

  static getValueWithFilterById = <S extends string, SC extends IBaseSort<S>>(
    idFilterField: string,
    id: string
  ): IQuery<S, SC> => ({
    iterator: new OffsetLimit({ limit: 1, offset: 0 }),
    filterBy: { [idFilterField]: new TextFilter({ text: id }) },
    sortBy: [],
  });

  static getValueByComponentType = <S extends string, SC extends IBaseSort<S>>(
    componentType: ComponentType,
    id?: string,
    listQuery?: IQuery<S, SC>,
    idFilterField = 'Any'
  ) =>
    componentType === 'editor' && !!id
      ? QueryHelpers.getValueWithFilterById<S, SC>(idFilterField, id)
      : (listQuery ?? QueryHelpers.getMaxValue<S, SC>());
}
