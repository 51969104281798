import { TagSerialized } from '@playq/octopus-common';

export const sortNewTagsWithTheSameOrderAsPrevTags = (prevTags: TagSerialized[], newTags: TagSerialized[]) => {
  const copyTags = newTags.slice(0);
  const changedTags = prevTags.reduce((acc: TagSerialized[], current) => {
    const tagWithSameKeyAndValueIdx = copyTags.findIndex(
      ({ key: newKey, value: newValue }) => newKey === current.key && newValue === current.value
    );
    if (tagWithSameKeyAndValueIdx >= 0) {
      const tagWithSameKeyAndValue = copyTags.splice(tagWithSameKeyAndValueIdx, tagWithSameKeyAndValueIdx + 1)[0];
      acc.push(tagWithSameKeyAndValue);
      return acc;
    }
    const tagWithSameKeyIdx = copyTags.findIndex(({ key: newKey }) => newKey === current.key);
    if (tagWithSameKeyIdx >= 0) {
      const tagWithSameKey = copyTags.splice(tagWithSameKeyIdx, tagWithSameKeyIdx + 1)[0];
      acc.push(tagWithSameKey);
    }
    return acc;
  }, []);
  return changedTags.concat(copyTags);
};
