import * as _ from 'lodash';

import {
  BinaryInfo,
  NodeVar,
  NodeVarBinary,
  NodeVarFlow,
  NodeVarItem,
  NodeVarLeaderboard,
  NodeVarPackage,
  NodeVarPlatformDependentBinary,
  Mutation,
  FlowID,
} from '@playq/octopus-common';
import { AppLadderId } from '@playq/services-clerk';
import { ItemID, PackageID } from '@playq/services-bookkeeper';
import { FileVersion, FileVersionOutdated } from '@playq/octopus2-files';

import { NodeErrors } from '/helpers/config/node';
import { services2 } from '/api/services2';
import { NodeEntity, TreeApiState, EntityNodes } from '/shared/NewTree/types';
import { traverse } from '/shared/NewTree/helpers/traverse';

const processError = <T>(
  nodeEntity: NodeEntity<T>[],
  entityNodes: EntityNodes,
  error: unknown,
  selectEntityID?: (entity: T) => string
) => {
  console.error(error);
  nodeEntity.forEach(({ id, entity }) => {
    entityNodes.set(id, {
      error: NodeErrors.failedToLoadEntity,
      id: selectEntityID?.(entity),
    });
  });
};

const processResponse = <Entity, EntityID>(
  responseEntities: Entity[],
  nodeEntities: NodeEntity<EntityID>[],
  entityNodes: EntityNodes,
  serializeId: (entityId: EntityID) => string,
  selectEntityId: (entity: Entity) => string
) => {
  nodeEntities.forEach((nodeEntity) => {
    const entity = responseEntities.find((e) => serializeId(nodeEntity.entity) === selectEntityId(e));
    if (entity !== undefined) {
      entityNodes.set(nodeEntity.id, {
        error: NodeErrors.failedToLoadEntity,
        id: selectEntityId(entity),
      });
      return;
    }
  });
};

export const fetchRemoteEntities = async (
  cb: (checkNodeVar: (nodeId: string, nodeVar: NodeVar) => void) => void,
  fingerpintId?: string
): Promise<EntityNodes> => {
  const entityNodes: EntityNodes = new Map();
  const binaryIds: NodeEntity<BinaryInfo>[] = [];
  const flowIds: NodeEntity<NodeVarFlow>[] = [];
  const itemIds: NodeEntity<NodeVarItem>[] = [];
  const leaderboardIds: NodeEntity<NodeVarLeaderboard>[] = [];
  const packageIds: NodeEntity<NodeVarPackage>[] = [];

  const checkNodeVar = (nodeId: string, nodeVar: NodeVar) => {
    if (nodeVar instanceof NodeVarBinary) {
      binaryIds.push({
        id: nodeId,
        entity: nodeVar.val,
      });
    } else if (nodeVar instanceof NodeVarFlow) {
      flowIds.push({
        id: nodeId,
        entity: nodeVar,
      });
    } else if (nodeVar instanceof NodeVarItem) {
      itemIds.push({
        id: nodeId,
        entity: nodeVar,
      });
    } else if (nodeVar instanceof NodeVarLeaderboard) {
      leaderboardIds.push({ id: nodeId, entity: nodeVar });
    } else if (nodeVar instanceof NodeVarPackage) {
      packageIds.push({
        id: nodeId,
        entity: nodeVar,
      });
    } else if (nodeVar instanceof NodeVarPlatformDependentBinary) {
      const binaries = nodeVar.binaries;
      for (const key in binaries) {
        const binary = binaries[key];
        binaryIds.push({
          id: nodeId,
          entity: binary,
        });
      }
    }
  };

  cb(checkNodeVar);

  const promises: Promise<void>[] = [];
  if (binaryIds.length > 0) {
    const grouped = _.groupBy(binaryIds, (binaryInfo) => `${binaryInfo.entity.id}#${binaryInfo.entity.ver}`);
    promises.push(
      services2.filesService
        .checkFileVersions(
          Object.keys(grouped).map((key) => {
            const [fileId, ver] = key.split('#');
            const id = new FileVersion();
            id.version = +ver;
            id.id = +fileId;
            return id;
          }),
          false
        )
        .then((data) =>
          data.bifold(
            (res) => {
              return processResponse(
                res.versions.filter((v) => !(v.state instanceof FileVersionOutdated)),
                binaryIds,
                entityNodes,
                (binaryInfo) => binaryInfo.id.toString(),
                (entity) => entity.fileVersion.id.toString()
              );
            },
            (err) => {
              throw err;
            }
          )
        )
        .catch((e) => processError(binaryIds, entityNodes, e, (en) => String(en.id)))
    );
  }

  if (flowIds.length > 0) {
    const grouped = _.groupBy(flowIds, (varFlow) => varFlow.entity.val.serialize());
    promises.push(
      services2.flowsService
        .checkFlows(Object.keys(grouped).map((key) => new FlowID(key)))
        .then((data) =>
          data.bifold(
            (res) =>
              processResponse(
                res.flows,
                flowIds,
                entityNodes,
                (flow) => flow.val.id.toString(),
                (entity) => entity.id.toString()
              ),
            (err) => {
              throw err;
            }
          )
        )
        .catch((e) => processError(flowIds, entityNodes, e))
    );
  }

  if (itemIds.length > 0) {
    const grouped = _.groupBy(itemIds, (varItem) => varItem.entity.val.serialize());
    promises.push(
      services2.appsInventoryService
        .checkItems(Object.keys(grouped).map((key) => new ItemID(key)))
        .then((data) =>
          data.bifold(
            (res) =>
              processResponse(
                res.items,
                itemIds,
                entityNodes,
                (item) => item.val.serialize(),
                (entity) => entity.serialize()
              ),
            (err) => {
              throw err;
            }
          )
        )
        .catch((e) => processError(itemIds, entityNodes, e))
    );
  }

  if (leaderboardIds.length > 0 && fingerpintId) {
    const groupedEntityIds = _.groupBy(leaderboardIds, (entity) => entity.entity.val);
    promises.push(
      services2.lboardsService
        .checkLadders(
          Object.keys(groupedEntityIds).map((key) => {
            const appLadderId = new AppLadderId();
            appLadderId.appId = fingerpintId;
            appLadderId.ladderId = key;
            return appLadderId;
          })
        )
        .then((data) =>
          data.bifold(
            (res) =>
              processResponse(
                res.ladders,
                leaderboardIds,
                entityNodes,
                (lb) => lb.val,
                (entity) => entity.ladderId
              ),
            (err) => {
              throw err;
            }
          )
        )
        .catch((e) => processError(leaderboardIds, entityNodes, e))
    );
  }

  if (packageIds.length > 0) {
    const grouped = _.groupBy(packageIds, (varPackage) => varPackage.entity.val.serialize());
    promises.push(
      services2.appsPackagesService
        .checkPackages(Object.keys(grouped).map((key) => new PackageID(key)))
        .then((data) =>
          data.bifold(
            (res) =>
              processResponse(
                res.packages,
                packageIds,
                entityNodes,
                (pkg) => pkg.val.serialize(),
                (entity) => entity.serialize()
              ),
            (err) => {
              throw err;
            }
          )
        )
        .catch((e) => processError(packageIds, entityNodes, e))
    );
  }

  return Promise.all(promises).then(() => entityNodes);
};

export const fetchTreeRemoteEntities = (
  nodes: TreeApiState['nodes'],
  rootNodes: TreeApiState['rootNodes'],
  fingerpintId?: string
) => {
  return fetchRemoteEntities((checkNodeVar) => {
    traverse(nodes, rootNodes, (id) => {
      const node = nodes.get(id);
      if (node?.type === 'value') {
        checkNodeVar(id, node.var);
      } else if (node?.type === 'array') {
        node.children.forEach((nodeVar) => {
          checkNodeVar(id, nodeVar);
        });
      }
    });
  }, fingerpintId);
};

export const fetchMutationRemoteEntities = (mutations: Mutation[], fingerpintId?: string) =>
  fetchRemoteEntities((checkNodeVar) => {
    for (const mutation of mutations) {
      for (const segment of mutation.segments) {
        for (const segmentValue of segment.values) {
          checkNodeVar(`${mutation.id}.${segment.id}.${segmentValue.node}.${segmentValue.path}`, segmentValue.var_);
        }
      }
    }
  }, fingerpintId);
