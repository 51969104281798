import {
  TraitDataType,
  TraitEntry,
  TraitEntryBoolean,
  TraitEntryDateTime,
  TraitEntryDouble,
  TraitEntryInt,
  TraitEntryLong,
  TraitEntryString,
  TraitGroup,
  Event,
  EventField,
  EventFieldCustom,
} from '@playq/octopus2-analytics';
import {
  Trait,
  TraitIntStruct,
  TraitLongStruct,
  TraitDoubleStruct,
  TraitStringStruct,
  TraitBooleanStruct,
  TraitDatetimeStruct,
} from '@playq/services-common-traits';

export function traitEntryToType(entry: TraitEntry) {
  switch (entry.getClassName()) {
    case TraitEntryInt.ClassName:
      return TraitDataType.Int;
    case TraitEntryLong.ClassName:
      return TraitDataType.Long;
    case TraitEntryDouble.ClassName:
      return TraitDataType.Double;
    case TraitEntryString.ClassName:
      return TraitDataType.String;
    case TraitEntryBoolean.ClassName:
      return TraitDataType.Boolean;
    case TraitEntryDateTime.ClassName:
      return TraitDataType.DateTime;
    default:
      throw new Error(`Unexpected trait entry class: ${entry.getClassName()}`);
  }
}

export function traitTypeToTraitClassName(type: TraitDataType): string {
  switch (type) {
    case TraitDataType.Int:
      return TraitIntStruct.FullClassName;
    case TraitDataType.Long:
      return TraitLongStruct.FullClassName;
    case TraitDataType.Double:
      return TraitDoubleStruct.FullClassName;
    case TraitDataType.String:
      return TraitStringStruct.FullClassName;
    case TraitDataType.Boolean:
      return TraitBooleanStruct.FullClassName;
    case TraitDataType.DateTime:
      return TraitDatetimeStruct.FullClassName;
    default:
      throw new Error('Unexpected trait entry class');
  }
}

export function traitEntryClassNameToTraitClassName(entry: TraitEntry): string {
  switch (entry.getClassName()) {
    case TraitEntryInt.ClassName:
      return TraitIntStruct.FullClassName;
    case TraitEntryLong.ClassName:
      return TraitLongStruct.FullClassName;
    case TraitEntryDouble.ClassName:
      return TraitDoubleStruct.FullClassName;
    case TraitEntryString.ClassName:
      return TraitStringStruct.FullClassName;
    case TraitEntryBoolean.ClassName:
      return TraitBooleanStruct.FullClassName;
    case TraitEntryDateTime.ClassName:
      return TraitDatetimeStruct.FullClassName;
    default:
      throw new Error(`Unexpected trait entry class: ${entry.getClassName()}`);
  }
}

export function traitClassNameToTrait(className: string): Trait {
  switch (className) {
    case TraitIntStruct.FullClassName:
      return new TraitIntStruct();
    case TraitLongStruct.FullClassName:
      return new TraitLongStruct();
    case TraitDoubleStruct.FullClassName:
      return new TraitDoubleStruct();
    case TraitStringStruct.FullClassName:
      return new TraitStringStruct();
    case TraitBooleanStruct.FullClassName:
      return new TraitBooleanStruct();
    case TraitDatetimeStruct.FullClassName:
      return new TraitDatetimeStruct();
    default:
      throw new Error(`Unexpected trait class: ${className}`);
  }
}

export function traitTypeToEntry(t: TraitDataType): TraitEntry {
  switch (t) {
    case TraitDataType.Int: {
      const res = new TraitEntryInt();
      res.default_ = 0;
      return res;
    }
    case TraitDataType.Long: {
      const res = new TraitEntryLong();
      res.default_ = 0;
      return res;
    }
    case TraitDataType.Double: {
      const res = new TraitEntryDouble();
      res.default_ = 0;
      return res;
    }
    case TraitDataType.Boolean: {
      const res = new TraitEntryBoolean();
      res.default_ = false;
      return res;
    }
    case TraitDataType.String: {
      const res = new TraitEntryString();
      res.default_ = '';
      return res;
    }
    case TraitDataType.DateTime: {
      const res = new TraitEntryDateTime();
      res.default_AsString = '2000-01-01T00:00:00.000+00:00';
      return res;
    }
    default:
      throw new Error(`Unexpected trait data type: ${t as TraitDataType}`);
  }
}

export function validateTraits(groups?: TraitGroup[]): string | undefined {
  if (groups === undefined) {
    return 'Unexpected undefined traits.';
  }

  const groupNames: { [key: string]: number } = {};
  for (const g of groups) {
    if (g.name.trim() === '') {
      // TODO Add trait regex validator
      return `Group names can't be empty.`;
    }

    if (g.name in groupNames) {
      return `Group names must be unique: '${g.name}'`;
    }

    if (g.entries.length < 1) {
      return `Group shout have at least one entry`;
    }

    groupNames[g.name] = 1;

    const entryNames: { [key: string]: number } = {};
    for (const ge of g.entries) {
      if (ge.name in entryNames) {
        return `Trait names must be unique: '${ge.name}' in group '${g.name}'`;
      }
      entryNames[ge.name] = 1;
    }
  }

  return undefined;
}

// TODO Restrict to having no 0-9 in front of the name
export const TRAITS_NAME_REGEX = /[^a-zA-Z-0-9_]/g;

export const getEventsGroupForFormulaEditor = (eventID: number, events: Event[]) => {
  const ev = events.find((e: Event) => e.id === eventID);
  const tg = new TraitGroup();
  tg.name = '$event';
  if (ev) {
    // Other values can be taken directly from traits, so no need to re-expose them
    const fields = ev.fields.filter((e: EventField) => e instanceof EventFieldCustom) as EventFieldCustom[];
    tg.entries = fields.map((f) => {
      const te = traitTypeToEntry(f.dataType);
      te.desc = f.description;
      te.name = f.name;
      return te;
    });
  } else {
    tg.entries = [];
  }
  return tg;
};
