import {
  EntityPromotionsResponse,
  EnvironmentPromotionsResponse,
  PromotionResponse,
  PromotionDefunct,
  SpacePromotionResponse,
} from '@playq/octopus-common';

export class PromotionsHelpers {
  static getPromoSpaces(version: number, promotions: EntityPromotionsResponse): string[] {
    const sandboxPromotions = this.getSandboxPromoSpaces(version, promotions?.sandbox);
    const livePromotions = this.getLivePromoSpaces(version, promotions?.live);

    return [...sandboxPromotions, ...livePromotions];
  }

  static getSandboxPromoSpaces(version: number, sandboxPromo: EnvironmentPromotionsResponse): string[] {
    const sandboxPromoSpaces =
      sandboxPromo?.default_ &&
      sandboxPromo.default_.version === version &&
      !(sandboxPromo.default_.state instanceof PromotionDefunct)
        ? ['Sandbox: default']
        : [];
    if (sandboxPromo?.custom.length) {
      sandboxPromo.custom.forEach((res: SpacePromotionResponse) => {
        if (res.promotion.version === version && !(res.promotion.state instanceof PromotionDefunct)) {
          const spaceName = res.spaceName ? res.spaceName : res.id.serialize();
          sandboxPromoSpaces.push(`Sandbox: ${spaceName}`);
        }
      });
    }

    return sandboxPromoSpaces;
  }

  static getLivePromoSpaces(version: number, livePromo?: PromotionResponse): string[] {
    return livePromo && livePromo.version === version && !(livePromo.state instanceof PromotionDefunct) ? ['Live'] : [];
  }
}
