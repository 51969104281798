import { createContext } from 'react';

import { ConsoleLogger, HybridClientTransport, JSONMarshallerImpl, ClientTransport } from '@playq/irt';

import { userHybrid, restEndpoint, wsEndpoint } from '/constants';
import { log } from '/helpers/log';
import { increaseProgressCount, decreaseProgressCount } from '/common/ProgressIndicator/ProgressIndicatorContext';

import { HTTPCachedTransport, TransportCache } from './cachedTransport';

// const timerLabel = 'delay';

const onSend = (_service: string, method: string) => {
  // console.groupCollapsed('transport');
  // console.time(timerLabel);
  log('services', `${method}:onSend`);
  increaseProgressCount();
};

const onFailure = () => {
  log('services', 'onFailure');
  // console.timeEnd(timerLabel);
  // console.groupEnd();
  decreaseProgressCount();
};

const onSuccess = (_service: string, method: string) => {
  log('services', `${method}:onSuccess`);
  // console.timeEnd(timerLabel);
  // console.groupEnd();
  decreaseProgressCount();
};

// Disable method caches along with api migration
export const createTransport = (
  marshaller: JSONMarshallerImpl,
  logger: ConsoleLogger,
  version: 2 | 3
): HTTPCachedTransport | HybridClientTransport => {
  if (userHybrid) {
    const hybrid = new HybridClientTransport(restEndpoint[version], wsEndpoint[version], marshaller, logger);
    hybrid.onSend = onSend;
    hybrid.onFailure = onFailure;
    hybrid.onSuccess = onSuccess;
    return hybrid;
  }
  const rest = new HTTPCachedTransport(new TransportCache(), restEndpoint[version], marshaller, logger);
  rest.onSend = onSend;
  rest.onFailure = onFailure;
  rest.onSuccess = onSuccess;

  return rest;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TransportContext = createContext<ClientTransport>();
