import _ from 'lodash';

import {
  EventAnalytical,
  EventField,
  EventFieldCustom,
  EventFieldHelpers,
  EventFieldTrait,
  EventLocal,
  Event,
  EventSystem,
  EventLocalSystem,
  TraitDataType,
  TraitDataTypeHelpers,
  TraitEntry,
  TraitEntryBoolean,
  TraitEntryDateTime,
  TraitEntryDouble,
  TraitEntryInt,
  TraitEntryLong,
  TraitEntryString,
  TraitGroup,
  Traits,
  EventTelemetryTracking,
  EventFieldSegment,
  Segment as IDLSegment,
} from '@playq/octopus2-analytics';

export enum EventFieldType {
  Trait = 'Trait',
  Custom = 'Custom',
  Segment = 'Segment',
}

export enum EventType {
  SystemEvent = 'SystemEvent',
  AnalyticalEvent = 'AnalyticalEvent',
  LocalEvent = 'LocalEvent',
}

export const EventMapperNamePattern = RegExp('^[a-zA-Z_]+[a-zA-Z0-9_]*$');

export class EventsMapperEntryTarget {
  static readonly IntSize = 120;
  static readonly StringSize = 50;
  static readonly DoubleSize = 50;
  static readonly DateTimeSize = 10;

  static intName(index: number) {
    return `int${index}`;
  }

  static longName(index: number) {
    return `long${index}`;
  }

  static stringName(index: number) {
    return `char${index}`;
  }

  static doubleName(index: number) {
    return `double${index}`;
  }

  static dateTimeName(index: number) {
    return `datetime${index}`;
  }
}

export function copyField<R = EventField>(field: EventField): R {
  return EventFieldHelpers.deserialize(EventFieldHelpers.serialize(field)) as unknown as R;
}

export function copyEvent(event: Event): Event {
  let nEvent;
  switch (event.getClassName()) {
    case EventAnalytical.ClassName: {
      nEvent = new EventAnalytical();
      break;
    }
    case EventSystem.ClassName: {
      nEvent = new EventSystem();
      break;
    }
    case EventLocalSystem.ClassName: {
      nEvent = new EventLocalSystem();
      break;
    }
    case EventLocal.ClassName: {
      nEvent = new EventLocal();
      break;
    }
  }

  if (!nEvent) {
    throw new Error(`Can't copy event`);
  }

  nEvent.id = event.id;
  nEvent.description = event.description;
  nEvent.fields = [...event.fields];
  nEvent.name = event.name;
  nEvent.meta = event.meta;
  nEvent.telemetryTracking = event.telemetryTracking
    ? new EventTelemetryTracking({
        isEnabled: event.telemetryTracking.isEnabled,
        splitByTarget: event.telemetryTracking.splitByTarget,
      })
    : undefined;

  return nEvent;
}

export function getTraitEntryDescription(field: EventField, traits?: Traits): string {
  if (!traits) {
    return '';
  }

  if (field instanceof EventFieldTrait) {
    const entry = getEventFieldTraitEntry(field, traits);
    return entry?.desc || '';
  }

  if (field instanceof EventFieldCustom) {
    return field.description || '';
  }

  return '';
}

export function eventFieldToTypeAndColor(field: EventField): [EventFieldType, 'primary' | 'secondary' | 'grey'] {
  switch (field.getClassName()) {
    case EventFieldTrait.ClassName:
      return [EventFieldType.Trait, 'primary'];
    case EventFieldCustom.ClassName:
      return [EventFieldType.Custom, 'secondary'];
    case EventFieldSegment.ClassName:
      return [EventFieldType.Segment, 'grey'];
    default:
      throw new Error(`Unexpected event field class: ${field.getClassName()}`);
  }
}

export function eventToType(event: Event) {
  switch (event.getClassName()) {
    case EventSystem.ClassName:
      return EventType.SystemEvent;
    case EventLocalSystem.ClassName:
      return EventType.SystemEvent;
    case EventAnalytical.ClassName:
      return EventType.AnalyticalEvent;
    case EventLocal.ClassName:
      return EventType.LocalEvent;
    default:
      throw new Error(`Unexpected event class: ${event.getClassName()}`);
  }
}

export function getTraitEntryDataType(entry: TraitEntry): TraitDataType {
  switch (entry.getClassName()) {
    case TraitEntryBoolean.ClassName:
      return TraitDataType.Boolean;
    case TraitEntryInt.ClassName:
      return TraitDataType.Int;
    case TraitEntryLong.ClassName:
      return TraitDataType.Long;
    case TraitEntryDouble.ClassName:
      return TraitDataType.Double;
    case TraitEntryString.ClassName:
      return TraitDataType.String;
    case TraitEntryDateTime.ClassName:
      return TraitDataType.DateTime;
    default:
      throw new Error(`Can't find entry dataType`);
  }
}

export function emptyFieldTrait(traitGroups: TraitGroup[], fields: EventField[]): EventFieldTrait {
  const group = traitGroups[0];
  const entry = group.entries[0];

  const nField = new EventFieldTrait();
  nField.traitGroup = group.name;
  nField.traitEntry = entry.name;
  nField.dataType = getTraitEntryDataType(entry);
  nField.target = getNextTargetOption(nField.dataType, fields);
  nField.meta = {};

  return nField;
}

export function emptyFieldCustom(fields: EventField[]): EventFieldCustom {
  const defaultDataType = TraitDataTypeHelpers.all[0];

  const nField = new EventFieldCustom();
  nField.name = '';
  nField.dataType = defaultDataType;
  nField.target = getNextTargetOption(defaultDataType, fields);
  nField.description = '';
  nField.meta = {};

  return nField;
}

export function emptyFieldSegment(segments: IDLSegment[], fields: EventField[]): EventFieldSegment {
  const nField = new EventFieldSegment();
  nField.dataType = TraitDataType.Int;
  nField.target = getNextTargetOption(TraitDataType.Int, fields);
  nField.meta = {};
  nField.segmentId = segments[0]?.id ?? '';

  return nField;
}

export const getNextID = (events: Event[]): number => {
  const maxIDEvent = _.maxBy(events, (e) => e.id);

  if (maxIDEvent) {
    return maxIDEvent.id >= 100 ? maxIDEvent.id + 1 : 100;
  }

  return 100;
};

export const getNextTargetOption = (dataType: TraitDataType, fields: EventField[]): string => {
  const options = getTargetOptions(dataType, fields);
  return options[0];
};

export const getTargetOptions = (dataType: string, fields: EventField[], except?: string): string[] => {
  let res: string[] = [];

  // eslint-disable-next-line default-case
  switch (dataType) {
    case TraitDataType.Int:
    case TraitDataType.Boolean:
    case TraitDataType.Long:
      for (let i = 1; i <= EventsMapperEntryTarget.IntSize; i++) {
        res.push(EventsMapperEntryTarget.intName(i));
      }
      break;

    case TraitDataType.Double:
      for (let i = 1; i <= EventsMapperEntryTarget.DoubleSize; i++) {
        res.push(EventsMapperEntryTarget.doubleName(i));
      }
      break;

    case TraitDataType.DateTime:
      for (let i = 1; i <= EventsMapperEntryTarget.DateTimeSize; i++) {
        res.push(EventsMapperEntryTarget.dateTimeName(i));
      }
      break;

    case TraitDataType.String:
      for (let i = 1; i <= EventsMapperEntryTarget.StringSize; i++) {
        res.push(EventsMapperEntryTarget.stringName(i));
      }
      break;
  }

  const taken: string[] = [];

  fields.forEach((f: EventField) => {
    if (except !== f.target) {
      taken.push(f.target);
    }
  });
  res = res.filter((t) => !taken.includes(t));

  return res;
};

export const checkUnique = (array: (string | number)[]) => array.length === Array.from(new Set(array)).length;

export const getEventFieldLabel = (field: EventField, allSegments: IDLSegment[]) => {
  if (field instanceof EventFieldTrait) {
    return `${field.traitGroup}.${field.traitEntry}`;
  }

  if (field instanceof EventFieldSegment) {
    const segment = allSegments.find(({ id }) => id === field.segmentId);

    if (!segment) {
      return field.segmentId;
    }

    return `${segment.name}_${segment.id}`;
  }

  return field.name;
};

export const getEventFieldTraitEntry = (field: EventFieldTrait, traits: Traits | TraitGroup[]) => {
  const groupSearchCallback = (g: TraitGroup) => g.name === field.traitGroup;
  const group = traits instanceof Traits ? traits.groups.find(groupSearchCallback) : traits.find(groupSearchCallback);

  return group?.entries.find((e: TraitEntry) => e.name === field.traitEntry);
};
