export enum StatusType {
  ongoing = 'ongoing',
  awaiting = 'awaiting',
  finished = 'finished',
}

export enum StatusColor {
  ongoing = '#81c784',
  awaiting = '#ffd54f',
  finished = '#e0e0e0',
}

export enum PromotionStatus {
  promoted = 'promoted',
  promotedNotTheLatest = 'promoted not latest',
  notPromoted = 'not promoted',
  defunct = 'defunct!',
}
