export interface IParsedTime {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function parseTime(s: number): IParsedTime {
  const result: IParsedTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  result.days = Math.floor(s / (3600 * 24));
  result.hours = Math.floor((s - result.days * (3600 * 24)) / 3600);
  result.minutes = Math.floor((s - result.days * (3600 * 24) - result.hours * 3600) / 60);
  result.seconds = Math.floor(s - result.days * (3600 * 24) - result.hours * 3600 - result.minutes * 60);

  return result;
}
