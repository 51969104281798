export interface UploadBinToAwsOptions {
  onProgress?: (progress: number) => void;
}

export const uploadBinToAws = (
  url: string,
  headers: Record<string, string[]>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any,
  options?: UploadBinToAwsOptions
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();

    req.open('PUT', url, true);
    Object.keys(headers).forEach((key) => {
      if (key !== 'host') {
        req.setRequestHeader(key, headers[key].join(', '));
      }
    });

    req.onreadystatechange = () => {
      if (req.readyState === 4) {
        if (req.status === 200) {
          resolve(req.response);
        } else {
          reject(req.responseText);
        }
      }
    };

    if (options?.onProgress) {
      req.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          options.onProgress?.(progress);
        }
      };
    }

    req.send(file);
  });
};
