import { GenericFailure } from '@playq/services-shared';

export const getErrorMessage = (error: GenericFailure | Error): string => {
  const errorMessage = error.message;

  if (error instanceof GenericFailure && errorMessage && typeof errorMessage === 'string') {
    const maybeDot = errorMessage.endsWith('.') ? '' : '.';

    return `${errorMessage}${maybeDot} Code: ${error.code}`;
  }

  return errorMessage;
};
