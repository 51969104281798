import { QueryKey } from '@tanstack/react-query';

import { OffsetLimit } from '@playq/services-shared';

import { QueryChangeType } from '/shared/Table/interfaces';

export const updateQueryKeysIterator = (queryKeys: QueryKey, change: QueryChangeType = QueryChangeType.NextPage) => {
  const { iterator, index } = queryKeys.reduce(
    (result: { iterator: OffsetLimit | OffsetLimit | undefined; index: number }, current, idx) => {
      if (current instanceof OffsetLimit || current instanceof OffsetLimit) {
        return { iterator: current, index: idx };
      }
      return result;
    },
    { iterator: undefined, index: -1 }
  );

  if (iterator === undefined) {
    return;
  }

  const newIterator = new OffsetLimit();
  newIterator.limit = iterator.limit;
  if (change === QueryChangeType.PrevPage) {
    newIterator.offset = iterator.offset - iterator.limit;
  }
  if (change === QueryChangeType.NextPage) {
    newIterator.offset = iterator.offset + iterator.limit;
  }
  if (change === QueryChangeType.FirstPage) {
    newIterator.offset = 0;
  }

  return [...queryKeys.slice(0, index), newIterator, ...queryKeys.slice(index + 1)];
};
