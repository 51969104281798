import { QueryKey } from '@tanstack/react-query';
import { Location } from 'history';

import { history } from '/Router/history';
import { SyntheticEventWithMetaKey } from '/common/models';

/**
 * @template S location state type
 */
type NavigateToURLParams<S = unknown> = {
  url: string | undefined;
  event?: SyntheticEventWithMetaKey;
  state?: S;
  noChanges?: boolean;
};

export const confirmAboutUnsavedChanges = (callback: () => void, noChanges?: boolean) => {
  if (noChanges !== false) {
    callback();
    return;
  }
  if (window.confirm('Are you sure? Unsaved changes will be lost.')) {
    callback();
  }
};

export const navigateToURL = <S = unknown>({ url, event, state, noChanges }: NavigateToURLParams<S>) => {
  if (!url) {
    return;
  }
  event?.preventDefault();
  event?.stopPropagation();
  if (event?.metaKey) {
    return window.open(url, '_blank');
  }

  const navigate = () => {
    history.push(url, state);
  };

  confirmAboutUnsavedChanges(navigate, noChanges);
};

export const getErrorBoundaryQueryKey = (pathname?: string): QueryKey =>
  !pathname ? ['errorBoundary'] : ['errorBoundary', pathname];

const getLastSlashIdx = (path: string) => path.lastIndexOf('/');

export const createUrlWithId = (id: string, path: string) => {
  const lastSlashIdx = getLastSlashIdx(path);
  return path.slice(0, lastSlashIdx + 1) + id;
};

export const pushIdToURL = (id: string, data?: { location?: Location; path?: string; replace?: boolean }) => {
  const { location, path } = data ?? {};
  const currentLocation = location ?? history.location;
  const pathname = path ?? currentLocation.pathname;
  history[data?.replace ? 'replace' : 'push'](createUrlWithId(id, pathname), currentLocation.state);
};
