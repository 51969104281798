import { ITableColumnsFilter } from './ITableColumnsFilter';
import { ITableColumnSort } from './ITableColumnSort';

export interface ITableQuery<S extends string> {
  filterBy: ITableColumnsFilter;
  sortBy: ITableColumnSort<S>[];
  page: number;
  rowsPerPage: number;
}

export enum QueryChangeType {
  FirstPage = 'FirstPage',
  NextPage = 'NextPage',
  PrevPage = 'PrevPage',
  EntitiesPerPage = 'EntitiesPerPage',
  Sort = 'Sort',
  SortClear = 'SortClear',
  Filter = 'Filter',
  FilterClear = 'FilterClear',
  Other = 'Other', // For all other cases, when a change is not using basic control of a table
}

export interface ITableChangeQuery {
  query: ITableQuery<string>;
  change: QueryChangeType;
}
