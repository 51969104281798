import { logSnackbarError } from '/common/snackbarService';
import { IJSONValue } from '/common/models';

/**
 *
 * @template T the type JSON is supposed to have after parsing a string
 */
export const parseJSON = <T = IJSONValue>(...params: Parameters<typeof JSON.parse>): T | null => {
  let parsedJSON: T | null = null;

  try {
    parsedJSON = JSON.parse(...params) as T;
  } catch (error) {
    logSnackbarError(error);
  }

  return parsedJSON;
};
