import _ from 'lodash';

import { KeyValuePairSerialized } from '@playq/octopus-common';

export const mapMetaToKeyValuePairsSerialized = (
  meta: { [key: string]: string | undefined }[]
): KeyValuePairSerialized[] => {
  const entries: [string, string | undefined][] = _.flatten(meta.map((item) => Object.entries(item)));
  const pairs = entries.map(([key, value]) => ({ key, value: value || undefined }));
  return pairs;
};
