import { HTTPClientTransport, IncomingData, Logger, JSONMarshaller, LogLevel, DummyLogger } from '@playq/irt';

import { TransportCache } from './transportCache';
import { ICachedTransport } from './cachedTransport';

export interface TRFailure {
  service: string;
  method: string;
  error: string;
  code: number;
}

export class HTTPCachedTransport extends HTTPClientTransport implements ICachedTransport {
  private cache: TransportCache;
  private readonly whitelist: {
    [key: string]: {
      either?: boolean;
      ttl?: number;
    };
  };

  private readonly resetTriggers: {
    [key: string]: Set<string>;
  };

  bypassCache: boolean;
  private superLogger: Logger;
  private superMarshaller: JSONMarshaller;
  private superTimeout: number;

  onFailureEx?: (e: TRFailure) => void;

  constructor(cache: TransportCache, endpoint: string, marshaller: JSONMarshaller, logger?: Logger) {
    super(endpoint, marshaller, logger);
    this.cache = cache;
    this.bypassCache = false;
    this.whitelist = {};
    this.resetTriggers = {};
    this.superLogger = logger || new DummyLogger();
    this.superMarshaller = marshaller;
    this.superTimeout = 60 * 1000;
  }

  private static whitelistKey(service: string, method: string): string {
    return `${service}::${method}`;
  }

  private makeResetTriggers(resetWhen: { service: string; method: string }[] | undefined, value: string) {
    if (!resetWhen) {
      return;
    }

    resetWhen.forEach(({ service, method }) => {
      const key = HTTPCachedTransport.whitelistKey(service, method);

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!this.resetTriggers[key]) {
        this.resetTriggers[key] = new Set();
      }

      this.resetTriggers[key].add(value);
    });
  }

  enableCache(
    service: string,
    method: string,
    resetWhen?: { service: string; method: string }[],
    either?: boolean,
    ttl?: number
  ) {
    const key = HTTPCachedTransport.whitelistKey(service, method);
    this.makeResetTriggers(resetWhen, key);
    this.whitelist[key] = { either, ttl };
  }

  disableCache(service: string, method: string) {
    delete this.whitelist[HTTPCachedTransport.whitelistKey(service, method)];
  }

  clearAll() {
    this.cache.clearAll();
  }

  private request(
    url: string,
    payload: string | null,
    successCallback: (content: string) => void,
    failureCallback: (error: string, code: number) => void
  ) {
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
      if (req.readyState === 4) {
        if (req.status === 200) {
          successCallback(req.responseText);
        } else {
          failureCallback(req.responseText, req.status);
        }
      }
    };

    if (payload) {
      req.open('POST', url, true);
      this.superLogger.logf(LogLevel.Debug, 'Header: Content-type: application/json');
      req.setRequestHeader('Content-type', 'application/json');
    } else {
      req.open('GET', url, true);
    }

    const headers = this.getHeaders();
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (headers) {
      for (const key in headers) {
        if (Object.prototype.hasOwnProperty.call(headers, key)) {
          req.setRequestHeader(key, headers[key]);
        }
      }
    }
    const auth = this.getAuthorization();
    if (auth) {
      req.setRequestHeader('Authorization', auth.toValue());
      this.superLogger.logf(LogLevel.Debug, `Header: Authorization: ${auth.toValue()}`);
    }
    req.timeout = this.superTimeout;
    req.send(payload);
  }

  setTimeout(timeout: number): void {
    this.superTimeout = timeout;
    super.setTimeout(timeout);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send(service: string, method: string, data: IncomingData): Promise<any> {
    const bypass = this.bypassCache;
    if (bypass) {
      return super.send(service, method, data);
    }

    const key = HTTPCachedTransport.whitelistKey(service, method);

    const resetMethods = this.resetTriggers[key];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (resetMethods) {
      resetMethods.forEach((k) => this.cache.clearByMethod(k));
    }

    const wl = this.whitelist[key];

    const res = this.cache.getRequest<string>(service, method, data);

    if (typeof res !== 'undefined') {
      return new Promise((resolve) => {
        const content = this.superMarshaller.Unmarshal(res);
        resolve(content);
      });
    }

    this.superLogger.logf(LogLevel.Debug, '====================================================');
    this.superLogger.logf(LogLevel.Debug, `Requesting ${service} service, method ${method}`);

    return new Promise((resolve, reject) => {
      const url = `${this.endpoint}/${service}/${method}`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const payload = data.serialize();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const payloadHasNoData = Object.keys(payload).length === 0 && payload.constructor === Object;
      const json = payloadHasNoData ? null : (this.superMarshaller.Marshal(payload) as string);
      this.superLogger.logf(LogLevel.Debug, `Endpoint: ${url}`);
      this.superLogger.logf(LogLevel.Debug, `Method: ${payloadHasNoData ? 'GET' : 'POST'}`);
      if (json !== null) {
        this.superLogger.logf(LogLevel.Trace, `Request Body:\n${json}`);
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.onSend) {
        this.onSend(service, method, json || '');
      }

      this.request(
        url,
        json,
        (successContent) => {
          this.superLogger.logf(LogLevel.Trace, `Response body:\n${successContent}`);
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (this.onSuccess) {
            this.onSuccess(service, method, successContent);
          }

          const content = this.superMarshaller.Unmarshal(successContent);
          if (!bypass) {
            // For Either request, we can check that it is Right data, not Left
            if (wl?.either) {
              const eitherContent = content as Record<string, unknown>;
              // We double check that isRight method actually available on the unmarshalled object
              if (Object.keys(eitherContent).length === 1 && Object.keys(eitherContent)[0] === 'Success') {
                this.cache.setRequest(service, method, data, successContent, wl.ttl);
              }
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            } else if (wl) {
              this.cache.setRequest(service, method, data, successContent, wl.ttl);
            }
          }

          resolve(content);
        },
        (failureContent: string, code: number) => {
          this.superLogger.logf(LogLevel.Error, `Failure:\n${failureContent}`);

          if (this.onFailureEx) {
            this.onFailureEx({ service, method, error: failureContent, code });
          }

          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (this.onFailure) {
            this.onFailure(service, method, failureContent);
          }

          reject(failureContent);
        }
      );
    });
  }
}
