import { Observable } from 'rxjs';

import { IBoundingRect } from '/hooks';

export type IElementResizeObservable = Observable<IBoundingRect>;

export const getElementResizeObservable = (element: Element): Observable<IBoundingRect> =>
  new Observable<IBoundingRect>((observer) => {
    const resizeObserver: ResizeObserver = new ResizeObserver(([resizeObserverEntry]: ResizeObserverEntry[]) => {
      const { width, height }: DOMRectReadOnly = resizeObserverEntry.contentRect;

      observer.next({ width, height });
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  });
