export enum PersistedQueryKeys {
  StoragePrefix = '#tg_query_',
  SpacesList = 'spaces-list-query',
  Experiments = 'experiments-query',
  Logs = 'logs-table',
  MassCoupons = 'mass-coupons-query',
  TargetedCoupons = 'targeted-coupons-query',
  InventoryModal = 'app-inventory-picker-query',
  Inventory = 'app-inventory-query',
  Packages = 'packagess-query',
  GameEvents = 'game-events-query',
  CloudFunctions = 'cloud-functions-query',
  Scripts = 'scripts',
  Leaderboards = 'leaderboards-query',
  ApiKeys = 'api-keys-manager',
  QueryBilling = 'query-billing-table',
  ConfiguredBilling = 'configured-billing-table',
  UnconfiguredBilling = 'unconfigured-billing-table',
  TagsManager = 'tags-manager',
  Matches = 'matches-query',
  Teams = 'teams-query',
  Flows = 'flows-query',
  SynapseProblems = 'synapse-problems-query',
  Copies = 'copies-query',
  SupportLeaderboards = 'support-ladders-query',
  Users = 'users-query',
  Integrations = 'integrations-query',
  WebHooks = 'webhooks-query',
  WebEvents = 'web-events-query',
  Files = 'files-query',
  Assets = 'assets-query',
  FilePicker = 'file-picker',
  AppsModal = 'apps-modal-query',
  Apps = 'apps-query',
  SupportTeams = 'teams-query-support',
  SearchTeams = 'teams-query-search',
  TeamMembers = 'team-members-query',
  SupportRequests = 'support-requests-query',
  Issues = 'issues-query',
  IssueAttachments = 'issue-attachments-query',
  SupportRequestHistory = 'support-request-history',
  Blueprints = 'blueprints',
  Dashboards = 'query-dashboard-list',
  Credentials = 'query-credentials',
  LadderInstanceScores = 'query-ladder-instance-scores',
  LadderInstanceDimensions = 'query-ladder-instance-dimensions',
  BlueprintRuns = 'blueprint-runs-query',
  BlueprintRunProcesses = 'blueprint-run-processes',
  Attachments = 'search_attachments',
  Devices = 'devices',
  ClusterTasks = 'cluster-tasks',
  ClusterRuns = 'cluster-runs',
  ClusterReport = 'cluster-report',
  Cluster = 'cluster',
  SpacePromotions = 'space-promotions',

  // CORP
  CorpGroups = 'corp-groups-query',
  CorpUsers = 'corp-users-query',
  CorpCredentials = 'corps-credentials-query',

  removeQueriesKeys = 'removeQuerriesKeys',
}
