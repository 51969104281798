/* eslint-disable @typescript-eslint/ban-types */
import { IObjectKey, IObjectToFreeze, IMapLike, ISetLike } from './types';

export const freezeDeepAsync = async (targetObj: Record<IObjectKey, unknown>) => {
  if (!isObjectToFreeze(targetObj)) {
    return;
  }

  const objKeys: IObjectKey[] = Object.keys(targetObj);
  const asyncFreezingResults: Promise<void>[] = [];

  for (let i = 0, len = objKeys.length; i < len; i++) {
    const val: unknown = targetObj[objKeys[i] as keyof IObjectToFreeze];

    asyncFreezingResults.push(handleObjectValueFreezingAsync(val));
  }

  await Promise.all(asyncFreezingResults);

  Object.freeze(targetObj);
};

const handleObjectValueFreezingAsync = (val: unknown): Promise<void> =>
  new Promise<void>((resolve: (_: void | PromiseLike<void>) => void) => {
    if (!isObjectToFreeze(val)) {
      return resolve();
    }

    const mockModifier = () => {};

    if (isMap(val)) {
      val.set = mockModifier;
      val.delete = mockModifier;

      return resolve();
    }

    if (isSet(val)) {
      val.add = mockModifier;
      val.delete = mockModifier;

      return resolve();
    }

    freezeDeepAsync(val);

    resolve();
  });

const isMap = (targetObj: object): targetObj is IMapLike => {
  return targetObj instanceof Map || targetObj instanceof WeakMap;
};

const isSet = (targetObj: object): targetObj is ISetLike => {
  return targetObj instanceof Set || targetObj instanceof WeakSet;
};

const isObjectToFreeze = (val: unknown): val is IObjectToFreeze => {
  return typeof val === 'object' && val !== null && !(val instanceof RegExp);
};
