import { WebSocketClientTransport, ConsoleLogger, JSONMarshallerImpl, Right } from '@playq/irt';
import {
  GatewayBuzzerBuzzerHandlers,
  AdminNotification,
  EphemeralNotification,
  MutationNotification,
  UsersTag,
} from '@playq/octopus-notifications';
import { OnlineDevicesSubscriptionBuzzerBuzzerHandlers, Device, DeviceID } from '@playq/octopus2-devices';
import { FlowerBlueprintBuzzerBuzzerHandlers } from '@playq/octopus2-analytics';
import { BlueprintRunId, BlueprintRun } from '@playq/services-flower';
import { ClientEvent } from '@playq/services-vortex';
import { CommonSuccess, GenericFailure } from '@playq/services-shared';

import { wsEndpoint } from '/constants';
import {
  buzzerBlueprintRunNotifications$,
  buzzerNotifications$,
  buzzerOnlineDevicesNotifications$,
} from '/common/buzzer/buzzerNotifications';

export type Context = Record<string, unknown>;

export class BuzzerHandlers extends GatewayBuzzerBuzzerHandlers<Context, string> {
  receiveUsersTag(_context: Context, notification: UsersTag) {
    buzzerNotifications$.next(notification);
    return Promise.resolve();
  }
  receiveMutation(_context: Context, notification: MutationNotification) {
    buzzerNotifications$.next(notification);
    return Promise.resolve();
  }
  receiveEphemeral(_context: Context, notification: EphemeralNotification) {
    buzzerNotifications$.next(notification);
    return Promise.resolve();
  }
  receiveAdmin(_context: Context, notification: AdminNotification) {
    buzzerNotifications$.next(notification);
    return Promise.resolve();
  }
}

export class BuzzerOnlineHandlers extends OnlineDevicesSubscriptionBuzzerBuzzerHandlers<Context, string> {
  status(_context: Context, status: Device) {
    buzzerOnlineDevicesNotifications$.next(status);
    return Promise.resolve();
  }
  event(_context: Context, device: DeviceID, event: ClientEvent) {
    buzzerOnlineDevicesNotifications$.next({ device, event });
    return Promise.resolve();
  }
}

export class BuzzerBlueprintRunHandlers extends FlowerBlueprintBuzzerBuzzerHandlers<Context, string> {
  statusUpdate(_context: Context, _blueprintId: BlueprintRunId, status: BlueprintRun) {
    buzzerBlueprintRunNotifications$.next(status);

    return Promise.resolve(new Right<GenericFailure, CommonSuccess>(new CommonSuccess()));
  }
}

export const createWebsocketTransport = (
  marshaller: JSONMarshallerImpl,
  logger: ConsoleLogger,
  version: 2 | 3
): WebSocketClientTransport<Context> => {
  const transport = new WebSocketClientTransport<Context>(wsEndpoint[version], marshaller, logger);

  transport.registerBuzzer(new BuzzerHandlers(marshaller));
  transport.registerBuzzer(new BuzzerOnlineHandlers(marshaller));
  transport.registerBuzzer(new BuzzerBlueprintRunHandlers(marshaller));

  return transport;
};
