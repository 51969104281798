export class ForbiddenFiles {
  static FileNames = [
    '.DS_Store',
    '.AppleDouble',
    '.LSOverride',
    '.DocumentRevisions-V100',
    '.fseventsd',
    '.Spotlight-V100',
    '.TemporaryItems',
    '.Trashes',
    '.VolumeIcon.icns',
    '.com.apple.timemachine.donotpresent',
    '.AppleDB',
    '.AppleDesktop',
    '.apdisk',
    'Thumbs.db',
    'ehthumbs.db',
    'ehthumbs_vista.db',
  ];

  static IsDeprecated(str: string): boolean {
    const fileName = str.toLocaleLowerCase();

    return ForbiddenFiles.FileNames.some((name: string) => name.toLocaleLowerCase() === fileName);
  }
}
