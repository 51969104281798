import { formatDate, OffsetData, OffsetUTCParams } from '/helpers';

export const getOffsetInfo = ({ startDate, endDate }: OffsetUTCParams): OffsetData => {
  const dateStartOffset: string = formatDate(startDate, 'ZZ');
  const dateEndOffset: string = formatDate(endDate, 'ZZ');
  const offsetDiff: number = parseInt(dateStartOffset) - parseInt(dateEndOffset);

  return {
    isEquals: offsetDiff === 0,
    startOffset: dateStartOffset,
    endOffset: dateEndOffset,
    difference: offsetDiff > 0 ? `+${offsetDiff}:00` : `${offsetDiff}:00`,
  };
};
