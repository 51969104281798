/* eslint-disable @typescript-eslint/no-explicit-any */
import { sha256 } from 'js-sha256';
import _ from 'lodash';

import {
  NodeHelpers as SystemNodeHelpers,
  Node,
  NodeGroup,
  NodeValue,
  NodeMeta,
  NodeVar,
  NodeVarHelpers,
  NodeVarInt,
  NodeVarLong,
  NodeVarDouble,
  NodeVarBool,
  NodeVarString,
  NodeVarDateTime,
  NodeVarBinary,
  NodeVarPlatformDependentBinary,
  NodeVarFlow,
  NodeVarPackage,
  NodeVarItem,
  NodeVarFormula,
  NodeVarLeaderboard,
  NodeArray,
} from '@playq/octopus-common';

import { defaultFormula } from '/helpers/model';
import { NodeArrayModel, NodeGroupModel, NodeModel, NodeValueModel } from '/shared/NewTree/types';

export enum NodeMetaKeys {
  readonly = 'readonly',
  childless = 'childless',
  color = 'color',
  diff = 'diff',
  diffMsg = 'diffMsg',
  description = 'description',
  virtualEntity = 'virtualEntity',
  error = 'error',
  entity = 'entity',
  originalCopiedNode = 'originalCopiedNode',
}

export enum NodeErrors {
  emptyName = `Node name can't be empty`,
  duplicateName = `Nodes at the same level must have unique names`,
  emptyValue = `Node value can't be empty`,
  emptyGroup = `Group should have at least one node`,
  emptyArray = `Array should have at least one variable`,
  invalidValue = `Invalid value type. Value should be of type: `,
  invalidFile = `File should be selected`,
  invalidFileVersion = 'File version should be selected',
  invalidChild = 'Invalid child',
  failedToLoadEntity = 'Failed to retrieve the remote entity',
  invalidBinaryLocationField = 'Invalid location field',
  missedVersion = 'This version is missing',
  fileNotFound = 'File not found',
  platfromDependentBinariesWithMissedVersion = 'Contains binaries with missed version',
  platfromDependentBinariesWithFileNotFound = 'Contains binaries with file not found',
  platfromDependentBinariesWithInvalidBinaries = 'Contains invalid binaries',
}

export interface INodeVarSchema {
  [key: string]: NodeVar | INodeVarSchema;
}

export const getRemoteEntityNodeVarError = (value: NodeVar, cachedErrors: (string | number)[]) => {
  let isInvalid = false;
  if (value instanceof NodeVarLeaderboard) {
    isInvalid = cachedErrors.includes(value.val);
  }
  if (value instanceof NodeVarPackage || value instanceof NodeVarItem) {
    isInvalid = cachedErrors.includes(value.val.serialize());
  }
  if (value instanceof NodeVarPlatformDependentBinary) {
    isInvalid = Object.values(value.binaries).some(({ id }) => cachedErrors.includes(String(id)));
  }
  if (value instanceof NodeVarFlow || value instanceof NodeVarBinary) {
    isInvalid = cachedErrors.includes(String(value.val.id));
  }
  return isInvalid ? NodeErrors.failedToLoadEntity : undefined;
};

export const getNodeArrayRemoteChildrenError = (node: NodeArray, cachedErrors: (string | number)[]) => {
  const hasError = node.children.some((child) => {
    return getRemoteEntityNodeVarError(child, cachedErrors);
  });
  if (hasError) {
    return NodeErrors.failedToLoadEntity;
  }
};

export const getRemoteEntityError = (node: Node, cachedErrors: (string | number)[]) => {
  if (node instanceof NodeValue) {
    return getRemoteEntityNodeVarError(node.value, cachedErrors);
  }
  if (node instanceof NodeArray) {
    return getNodeArrayRemoteChildrenError(node, cachedErrors);
  }
  if (node instanceof NodeGroup) {
    const hasError = node.children.some((child) => getRemoteEntityError(child, cachedErrors));
    if (hasError) {
      return NodeErrors.failedToLoadEntity;
    }
  }
};

export const getRemoteEntityNodeModelValueError = (node: NodeValueModel, cachedErrors: (string | number)[]) =>
  getRemoteEntityNodeVarError(node.var, cachedErrors);

export const getNodeModelArrayRemoteChildrenError = (node: NodeArrayModel, cachedErrors: (string | number)[]) =>
  getNodeArrayRemoteChildrenError(node as unknown as NodeArray, cachedErrors);

export const getNodeModelGroupRemoteChildrenError = (node: NodeGroupModel, nodes: Map<string, NodeModel>) => {
  const hasInvalidChild = node.children.some((id) => !!nodes.get(id)?.error);
  if (hasInvalidChild) {
    return NodeErrors.failedToLoadEntity;
  }
};

export const validateStateNodes = (nodes: Map<string, NodeModel>, cachedErrors: (string | number)[]) => {
  const sortedNodes: NodeModel[] = [];
  nodes.forEach((node) => {
    if (node.type === 'value') {
      sortedNodes.unshift(node);
    }
    if (node.type === 'array') {
      sortedNodes.push(node);
    }
  });
  nodes.forEach((node) => {
    if (node.type === 'group') {
      sortedNodes.push(node);
    }
  });
  sortedNodes.forEach((node) => {
    if (node.type === 'value') {
      node.error = getRemoteEntityNodeModelValueError(node, cachedErrors);
    }
    if (node.type === 'array') {
      node.error = getNodeModelArrayRemoteChildrenError(node, cachedErrors);
    }
    if (node.type === 'group') {
      node.error = getNodeModelGroupRemoteChildrenError(node, nodes);
    }
  });
};

export class NodeHelpers {
  static typeLabels = {
    Values: {
      [NodeVarBool.ClassName]: 'Bool',
      [NodeVarDateTime.ClassName]: 'Date',
      [NodeVarDouble.ClassName]: 'Double',
      [NodeVarInt.ClassName]: 'Int',
      [NodeVarLong.ClassName]: 'Long',
      [NodeVarString.ClassName]: 'String',
    },
    Remote: {
      [NodeVarBinary.ClassName]: 'Binary',
      [NodeVarFlow.ClassName]: 'Flow',
      [NodeVarFormula.ClassName]: 'Formula',
      [NodeVarItem.ClassName]: 'Item',
      [NodeVarLeaderboard.ClassName]: 'Leaderboard',
      [NodeVarPackage.ClassName]: 'Package',
    },
    Objects: {
      [NodeArray.ClassName]: 'Array',
      [NodeGroup.ClassName]: 'Group',
      [NodeVarPlatformDependentBinary.ClassName]: 'Platform Binary',
    },
  } as const;

  static getTypeLabel(nodeType: string): string {
    // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
    return Object.values(NodeHelpers.typeLabels).find((groupedTypes) => groupedTypes[nodeType])?.[nodeType] as string;
  }

  static isNode(value: unknown): value is Node {
    return value !== undefined && value !== null && SystemNodeHelpers.isInstanceOf(value);
  }

  static findByName(name: string, node: NodeGroup): Node | undefined {
    const isNameTheSame = (n: Node) => node.id !== n.id && n.name === name;
    return NodeHelpers.findOne(node, isNameTheSame, false);
  }

  static findById(id: string, node: NodeGroup): Node | undefined {
    const isIdTheSame = (n: Node) => n.id === id;
    return NodeHelpers.findOne(node, isIdTheSame, true);
  }

  static findOne(node: NodeGroup, predicate: (node: Node) => boolean, recursive = true): Node | undefined {
    if (predicate(node)) {
      return node;
    }

    if (!node.children.length) {
      return undefined;
    }

    let nextIndex = 0;
    const nodes = node.children;

    let result: Node | undefined;
    while (nextIndex < nodes.length && !result) {
      const nextNode = nodes[nextIndex];
      if (predicate(nextNode)) {
        result = nextNode;
        break;
      }

      if (recursive && nextNode.getClassName() === NodeGroup.ClassName) {
        result = NodeHelpers.findOne(nextNode as NodeGroup, predicate, recursive);
      }

      nextIndex += 1;
    }

    return result;
  }

  static getNodeVar(node: Node): any {
    if (node.getClassName() === NodeGroup.ClassName) {
      const nodeGroup = node as NodeGroup;
      if (!nodeGroup.children.length) {
        return {};
      }
      return nodeGroup.children.reduce((acc: Record<string, any>, next: Node) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        acc[next.name] = NodeHelpers.getNodeVar(next);
        return acc;
      }, {});
    }
    return (node as NodeValue).value;
  }

  static updateNodeByValueSchema(node: NodeGroup, schema: INodeVarSchema): NodeGroup {
    node.children = node.children.reduce((acc: Node[], n: Node) => {
      const value = schema[n.name];

      if (_.isEmpty(value)) {
        return acc;
      }

      if (n.getClassName() === NodeGroup.ClassName) {
        const nGroup = NodeHelpers.updateNodeByValueSchema(n as NodeGroup, value as INodeVarSchema);
        acc.push(nGroup);
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      } else if (value.getClassName) {
        acc.push(NodeHelpers.updateNodeValue(n as NodeValue, value as NodeVar));
      }

      delete schema[n.name];
      return acc;
    }, []);

    Object.keys(schema).forEach((key: string) => {
      const value = schema[key];

      if (value === undefined) {
        return;
      }

      let nNode: Node;
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (value.getClassName) {
        nNode = NodeHelpers.createNodeByValue(key, value as NodeVar);
      } else if (typeof value === 'object' && !_.isEmpty(value)) {
        nNode = NodeHelpers.createNodeGroupBySchema(key, value as INodeVarSchema);
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Variable 'nNode' is used before being assigned.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (nNode) {
        node.children.push(nNode);
      }
    });

    return node;
  }

  static updateNodeValue(node: NodeValue, value: NodeVar): NodeValue {
    if (node.value.getClassName() !== value.getClassName()) {
      if (node.name === 'duration' && node.value.getClassName() === NodeVarLong.ClassName) {
        // TODO remove when fix errors with converter
        console.warn('invalid duration node was converted into the node with NodeVarInt value');
      } else {
        throw new Error(`Can't set invalid value to ${node.name}`);
      }
    }

    node.value = value;
    return node;
  }

  static createNodeByValue(name: string, value: NodeVar): NodeValue {
    const nNode = this.emptyNodeValue(name);
    nNode.value = value;

    return nNode;
  }

  static getNodeType(node: Node): string {
    return node instanceof NodeValue ? node.value.getClassName() : node.getClassName();
  }

  static createValByType(type: string): NodeVar | null {
    switch (type) {
      case NodeVarInt.ClassName:
        return new NodeVarInt({ val: 0 });
      case NodeVarLong.ClassName:
        return new NodeVarLong({ val: 0 });
      case NodeVarDouble.ClassName:
        return new NodeVarDouble({ val: 0 });
      case NodeVarBool.ClassName:
        return new NodeVarBool({ val: false });
      case NodeVarString.ClassName:
        return new NodeVarString({ val: '' });
      case NodeVarDateTime.ClassName: {
        const nodeVarDateTime = new NodeVarDateTime();
        nodeVarDateTime.val = new Date();
        return nodeVarDateTime;
      }
      case NodeVarBinary.ClassName:
        return new NodeVarBinary();
      case NodeVarPlatformDependentBinary.ClassName:
        return new NodeVarPlatformDependentBinary();
      case NodeVarFormula.ClassName: {
        const nodeVarFormula = new NodeVarFormula();
        nodeVarFormula.val = defaultFormula();
        return nodeVarFormula;
      }
      case NodeVarPackage.ClassName:
        return new NodeVarPackage();
      case NodeVarItem.ClassName:
        return new NodeVarItem();
      case NodeVarFlow.ClassName:
        return new NodeVarFlow();
      case NodeVarLeaderboard.ClassName:
        return new NodeVarLeaderboard();
      default:
        return null;
    }
  }

  static createNodeByType(type: string): Node {
    if (type === NodeGroup.ClassName) {
      return new NodeGroup({
        name: 'New_Group',
        children: [],
        id: this.randomNodeID(),
        hidden: undefined,
        meta: undefined,
      });
    }
    if (type === NodeArray.ClassName) {
      return new NodeArray({
        name: 'Node_Array',
        children: [],
        id: this.randomNodeID(),
        hidden: undefined,
        meta: undefined,
      });
    }
    const nodeValue = new NodeValue();
    nodeValue.name = `New_${this.typeLabels.Values[type as keyof typeof NodeHelpers.typeLabels.Values]}`;
    nodeValue.id = this.randomNodeID();
    nodeValue.value = NodeHelpers.createValByType(type) as NodeVar;
    nodeValue.hidden = undefined;
    nodeValue.meta = undefined;
    return nodeValue;
  }

  static createNodeGroupBySchema(name: string, schema: INodeVarSchema) {
    const nGroup = new NodeGroup();
    nGroup.name = name;
    nGroup.id = this.randomNodeID();

    Object.keys(schema).forEach((key: string) => {
      const value = schema[key];

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (value.getClassName) {
        const nNode = NodeHelpers.createNodeByValue(key, value as NodeVar);
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (nNode) {
          nGroup.children.push(nNode);
        }
      } else if (typeof value === 'object' && !_.isEmpty(value)) {
        const nNode = NodeHelpers.createNodeGroupBySchema(key, value as INodeVarSchema);
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (nNode) {
          nGroup.children.push(nNode);
        }
      }
    });

    return nGroup;
  }

  static updateType(node: Node, type: string): Node {
    const newNode = NodeHelpers.createNodeByType(type);
    newNode.hidden = node.hidden;
    newNode.name = node.name;
    newNode.id = node.id;
    newNode.meta = node.meta;
    return newNode;
  }

  static cloneNode(node: Node): Node {
    const newNode = SystemNodeHelpers.deserialize(SystemNodeHelpers.serialize(node));
    NodeHelpers.traverse(newNode, (_parent, child) => {
      child.id = NodeHelpers.randomNodeID();
    });
    return newNode;
  }

  static getMeta(node: Node, key: NodeMetaKeys): string | undefined {
    if (node.meta) {
      return node.meta.general[key];
    }
  }

  static setMeta(node: Node, key: NodeMetaKeys, value: string) {
    if (!node.meta) {
      node.meta = new NodeMeta();
    }
    node.meta.general[key] = value;
  }

  static deleteMeta(node: Node, ...keys: NodeMetaKeys[]) {
    if (node.meta) {
      for (const metaKey of keys) {
        delete node.meta.general[metaKey];
      }
      if (Object.keys(node.meta.general).length === 0 || Object.values(node.meta.general).every((v) => !v)) {
        node.meta = undefined;
        delete node.meta;
      }
    }
  }

  static randomNodeID(extraMessage?: string): string {
    const timestamp = new Date().getTime();
    return sha256(`${timestamp}_${extraMessage ?? ''}_${Math.random()}`).slice(0, 7);
  }

  static emptyNodeGroup(name: string): NodeGroup {
    const group = new NodeGroup();
    group.id = this.randomNodeID();
    group.name = name;
    return group;
  }

  static emptyNodeValue(name: string): NodeValue {
    const value = new NodeValue();
    value.id = this.randomNodeID();
    value.name = name;

    return value;
  }

  static deepChildren(node: Node): Node[] {
    let result: Node[] = [];

    if (!(node instanceof NodeGroup) || !node.children.length) {
      return [];
    }

    node.children.forEach((child) => {
      result.push(child);

      if (child instanceof NodeGroup && child.children.length) {
        result = result.concat(NodeHelpers.deepChildren(child));
      }
    });

    return result;
  }

  static validate(node: Node, cachedErrors: string[] = []): NodeErrors | string | undefined {
    const remoteEntityError = getRemoteEntityError(node, cachedErrors);
    if (remoteEntityError) {
      return remoteEntityError;
    }
    if (!node.name) {
      return NodeErrors.emptyName;
    }
    if (node instanceof NodeGroup) {
      if (!node.children.length) {
        return NodeErrors.emptyGroup;
      }
    }
    if (node instanceof NodeArray && node.children.some((child) => 'val' in child && _.isNil(child.val))) {
      return NodeErrors.emptyValue;
    }
    if (node instanceof NodeValue) {
      const { value } = node;
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!value) {
        return NodeErrors.emptyValue;
      }

      if (
        value instanceof NodeVarItem ||
        value instanceof NodeVarPackage ||
        value instanceof NodeVarFlow ||
        value instanceof NodeVarLeaderboard
      ) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!value.val) {
          return NodeErrors.emptyValue;
        }
      }

      if (value instanceof NodeVarBinary) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!value.val) {
          return NodeErrors.emptyValue;
        }

        if (!value.val.ver) {
          return NodeErrors.invalidFileVersion;
        }
        if (!value.val.id) {
          return NodeErrors.invalidFile;
        }
      }
    }
  }

  static traverse(data: Node | Node[], cb: (parent: NodeGroup | null, node: Node) => void): void {
    const stack: { parent: NodeGroup | null; node: Node }[] = [];
    if (Array.isArray(data)) {
      data.forEach((node) => stack.push({ node, parent: null }));
    } else {
      stack.push({ node: data, parent: null });
    }

    while (stack.length > 0) {
      const stackNode = stack.pop();
      if (stackNode) {
        const { parent, node } = stackNode;
        cb(parent, node);
        if (node instanceof NodeGroup) {
          node.children.forEach((child) => {
            stack.push({ parent: node, node: child });
          });
        }
      }
    }
  }

  static isNodeVar(value: unknown): value is NodeVar {
    return value !== null && value !== undefined && NodeVarHelpers.isInstanceOf(value);
  }

  static isPrimitiveNodeVar(
    value: unknown
  ): value is
    | NodeVarInt
    | NodeVarLong
    | NodeVarDouble
    | NodeVarBool
    | NodeVarString
    | NodeVarDateTime
    | NodeVarFormula {
    if (!_.isObjectLike(value) || !NodeHelpers.isNodeVar(value)) {
      return false;
    }
    const primitiveNodeVars = [
      NodeVarInt,
      NodeVarLong,
      NodeVarDouble,
      NodeVarBool,
      NodeVarString,
      NodeVarDateTime,
      NodeVarFormula,
    ];
    return primitiveNodeVars.some((type) => value instanceof type);
  }

  static isRemoteEntityNodeVar(
    value: unknown
  ): value is NodeVarLeaderboard | NodeVarPackage | NodeVarItem | NodeVarFlow {
    if (!_.isObjectLike(value) || !NodeHelpers.isNodeVar(value)) {
      return false;
    }
    return (
      value instanceof NodeVarLeaderboard ||
      value instanceof NodeVarPackage ||
      value instanceof NodeVarItem ||
      value instanceof NodeVarFlow
    );
  }
}
