import greenlet from 'greenlet';

type GetFileSha256 = (file: Blob) => Promise<string>;

export const getFileSha256: GetFileSha256 = greenlet(async (file: Blob) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Promise((resolve) => {
    reader.onload = async () => {
      const hashBuffer = await crypto.subtle.digest('SHA-256', reader.result as ArrayBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const fileSha = hashArray.map((b) => `00${b.toString(16)}`.slice(-2)).join('');
      resolve(fileSha);
    };
  });
});
