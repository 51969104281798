import { sha256 } from 'js-sha256';

import { IncomingData } from '@playq/irt';

const DEFAULT_CACHE_CAPACITY = 100;
const DEFAULT_CACHE_TTL = 1000 * 60 * 5; // 5 minutes

type CacheKey = string;

interface ICacheRecord {
  expiresAt: number;
  value: unknown;
}

function getServiceRequestKey(service: string, method: string, data: IncomingData): string {
  return `${service}::${method}::${sha256(JSON.stringify(data.serialize()))}`;
}

interface ICacheRecords {
  [key: string]: ICacheRecord;
}

export class TransportCache {
  private keys: CacheKey[];
  private records: ICacheRecords;
  private readonly capacity: number;
  private readonly defaultTTL: number;

  constructor(capacity: number = DEFAULT_CACHE_CAPACITY, defaultTTL: number = DEFAULT_CACHE_TTL) {
    this.capacity = capacity <= 0 ? 0 : capacity;
    this.defaultTTL = defaultTTL;
    this.keys = [];
    this.records = {};
  }

  getRequest<T = unknown>(service: string, method: string, data: IncomingData): T | undefined {
    return this.get<T>(getServiceRequestKey(service, method, data));
  }

  get<T = unknown>(key: string): T | undefined {
    const record = this.records[key];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!record) {
      return undefined;
    }

    if (Date.now() >= record.expiresAt) {
      this.clear(key);
      return undefined;
    }

    return record.value as T;
  }

  clearExpired() {
    // We need to make a copy because clear method does slicing and we won't
    // be able to iterate well
    const copy = this.keys.slice();
    const now = Date.now();
    for (const k of copy) {
      if (now < this.records[k].expiresAt) {
        continue;
      }

      this.clear(k);
    }
  }

  clearAll() {
    this.keys = [];
    this.records = {};
  }

  clearRequest(service: string, method: string, data: IncomingData) {
    this.clear(getServiceRequestKey(service, method, data));
  }

  clearByMethod(methodKey: string) {
    const [keys, records] = Object.entries(this.records).reduce(
      ([accKeys, accRecords]: [CacheKey[], ICacheRecords], [key, value]) => {
        if (!key.startsWith(methodKey)) {
          accRecords[key] = value;
          accKeys.push(key);
        }

        return [accKeys, accRecords];
      },
      [[], {}]
    );

    this.records = records;
    this.keys = keys;
  }

  clear(key: string) {
    if (!(key in this.records)) {
      return;
    }

    delete this.records[key];
    this.keys.splice(this.keys.indexOf(key), 1);
  }

  setRequest<T = unknown>(service: string, method: string, data: IncomingData, value: T, ttl?: number) {
    this.set(getServiceRequestKey(service, method, data), value, ttl);
  }

  set<T = unknown>(key: string, value: T, ttl?: number) {
    if (this.capacity === 0) {
      // No need to cache if we set cache to zero
      return;
    }

    const expiresAt = Date.now() + (typeof ttl === 'undefined' ? this.defaultTTL : ttl);

    if (key in this.records) {
      const rec = this.records[key];
      rec.expiresAt = expiresAt;
      rec.value = value;
      return;
    }

    if (this.capacity === this.keys.length) {
      delete this.records[this.keys[0]];
      this.keys.splice(0, 1);
    }

    this.keys.push(key);
    this.records[key] = {
      expiresAt,
      value,
    };
  }
}
