import { QueryClient, QueryKey } from '@tanstack/react-query';
import { isEqual } from 'lodash';

export type RemoveQueriesExceptExcludedArgs = {
  removeQueriesKeysArray: QueryKey[];
  excludedQueryKey?: QueryKey;
  queryClient: QueryClient;
};

/**
 *
 * @param removeQueriesKeysArray - queryKeys that should be removed
 * @param excludedQueryKey - queryKey that should not be removed
 * @returns
 */
export const removeQueriesExceptExcluded = ({
  removeQueriesKeysArray,
  excludedQueryKey,
  queryClient,
}: RemoveQueriesExceptExcludedArgs) => {
  removeQueriesKeysArray.forEach((removeQueriesKeys) => {
    queryClient.removeQueries({
      queryKey: removeQueriesKeys,
      predicate: ({ options: queryOptions }) => {
        if (!excludedQueryKey) {
          return true;
        }
        if (!queryOptions?.queryKey) {
          return false;
        }
        return !isEqual(queryOptions.queryKey, excludedQueryKey);
      },
    });
  });
};
