export const generateIdWithServiceId = <T extends { id: string; servicesCompanyId: string }>(
  Constructor: new () => T,
  servicesId?: string,
  id?: string
): T | undefined => {
  if (!servicesId || !id) {
    return;
  }

  const newType = new Constructor();
  newType.servicesCompanyId = servicesId;
  newType.id = id;
  return newType;
};
