import { NIL as NIL_UUID } from 'uuid';

import { Environment } from '@playq/octopus-common';
import { TimePrecision } from '@playq/octopus-common';

import { IDeepReadonly } from '/common/models/interfaces/deepReadonly';
import { freezeDeepAsync } from '/helpers/freezeDeepAsync/freezeDeepAsync';

const APP_NAME_KEY = 'appName';
const APPS_PATH = '/apps';
const APP_ROUTE = `:${APP_NAME_KEY}`;

const CONSTANTS = {
  userHybrid: false,
  restEndpoint: {
    2: '/api/v2',
    3: '/api/v3',
  },
  wsEndpoint: {
    2: `${__ENV_CFG__.wsEndpoint}/api/v2/ws`,
    3: `${__ENV_CFG__.wsEndpoint}/api/v3/ws`,
  },
  awsEndpoint: 'octopusconfigs.playq.net',
  oauth2ClientID: __ENV_CFG__.auth.googleClientId,
  spinnerDelay: 200,
  companyId: 101,
  entitiesLimit: 10,
  entitiesLimitOptions: [10, 20, 50, 100],
  iteratorMaxLimit: 32767,
  appsPath: APPS_PATH,
  basePath: `${APPS_PATH}/${APP_ROUTE}`,
  appRoute: APP_ROUTE,
  appNameKey: APP_NAME_KEY,
  defaultReccurentPatternStride: 1,
  publicPath: `${__CI_BUILD__ || '.'}`,
  zeroGuid: NIL_UUID,
  defaultStorageKey: '#tg_',
  envColors: {
    [Environment.Sandbox]: '#ffc65d',
    [Environment.Live]: '#d96557',
  },
  preferredThemeModeLocalStorageKey: 'ui-preferred-theme-mode',
  urlRegExp: RegExp(
    '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})'
  ),
  tooltipEnterDelay: 1500,
  timeUnitPrecisionMillisecondValues: {
    [TimePrecision.Second]: 1000,
    [TimePrecision.Minute]: 60000,
    [TimePrecision.Hour]: 3.6e6,
    [TimePrecision.Day]: 8.64e7,
    [TimePrecision.Month]: 2.628e9,
    [TimePrecision.Year]: 3.154e10,
  },
  defaultTableTotal: -1,
  relativeCreateURL: 'create',
  tagsKeyMinLength: 3,
  tagsKeyLengthError: 'Key length must be at least 3 characters',
  entityNotFoundErrorCode: 'EntityNotFound',
  minLongValue: Math.pow(10, 10),
  fileContentExistsError: 'File content exists.',
  defaultFileType: 'application/octet-stream',
};

(async () => {
  await freezeDeepAsync(CONSTANTS);
})();

export const {
  userHybrid,
  restEndpoint,
  wsEndpoint,
  awsEndpoint,
  oauth2ClientID,
  spinnerDelay,
  companyId,
  entitiesLimit,
  entitiesLimitOptions,
  iteratorMaxLimit,
  appsPath,
  basePath,
  appRoute,
  appNameKey,
  defaultReccurentPatternStride,
  publicPath,
  zeroGuid,
  defaultStorageKey,
  envColors,
  preferredThemeModeLocalStorageKey,
  urlRegExp,
  tooltipEnterDelay,
  timeUnitPrecisionMillisecondValues,
  defaultTableTotal,
  relativeCreateURL,
  tagsKeyMinLength,
  tagsKeyLengthError,
  entityNotFoundErrorCode,
  minLongValue,
  fileContentExistsError,
  defaultFileType,
}: IDeepReadonly<typeof CONSTANTS> = CONSTANTS;
