import { ThemeMode } from '/common/models';

export function setThemeModeStylingMetadata(themeMode: ThemeMode) {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!themeMode) {
    console.error('The themeMode is not provided!');

    return;
  }

  const documentElement: HTMLElement = document?.documentElement;

  if (typeof documentElement?.dataset !== 'object' || typeof documentElement?.classList !== 'object') {
    console.error('Probably, an app runs outside of the browser environment. The document object is unavailable.');

    return;
  }
  document.documentElement.dataset.themeMode = themeMode;
}
