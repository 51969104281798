import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDialogStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '10px 20px',
  },
  dialogActions: {
    margin: 20,
  },
  dialogHeader: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: theme.spacing(4),
    justifyContent: 'space-between',
  },
  dialogHeaderAction: {
    margin: 0,
  },
}));
