import { createContext, MutableRefObject, useEffect, useState } from 'react';
import { asyncScheduler, Observable, Subject } from 'rxjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { first, observeOn } from 'rxjs/operators';

import { FCWithChildren } from '/common/models';

import { IChangeProgressCount } from './types';

const subject$ = new Subject<number>();
const progressCountChanged$ = new Subject<void>();
const isProgressCountPausedRef: MutableRefObject<boolean> = { current: false };

const getProgressCountChanged = (): Observable<void> =>
  progressCountChanged$.asObservable().pipe(first(), observeOn(asyncScheduler));

export const pauseNewProgressCount = () => {
  isProgressCountPausedRef.current = true;
};

export const resumeNewProgressCount = () => {
  isProgressCountPausedRef.current = false;
};

export const decreaseProgressCount: IChangeProgressCount = () => {
  subject$.next(-1);
  return getProgressCountChanged();
};

export const increaseProgressCount: IChangeProgressCount = () => {
  if (!isProgressCountPausedRef.current) {
    subject$.next(1);
  }

  return getProgressCountChanged();
};

export const ProgressIndicatorContext = createContext(false);
export const ProgressIndicatorProvider: FCWithChildren = ({ children }) => {
  const [progressCount, setProgressCount] = useState(0);

  useEffect(() => {
    const sub = subject$
      .asObservable()
      .pipe(observeOn(asyncScheduler))
      .subscribe((v: number) => {
        setProgressCount((s) => {
          const next = s + v;

          return next < 0 ? 0 : next;
        });

        progressCountChanged$.next();
      });

    return () => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, []);

  return <ProgressIndicatorContext.Provider value={progressCount > 0}>{children}</ProgressIndicatorContext.Provider>;
};
