import { TreeApiState } from '../types';

export const traverse = (nodes: TreeApiState['nodes'], nodesId: string | string[], fn: (node: string) => void) => {
  if (Array.isArray(nodesId)) {
    nodesId.forEach((id) => traverse(nodes, id, fn));
  } else {
    fn(nodesId);
    const node = nodes.get(nodesId);
    if (node?.type === 'group') {
      traverse(nodes, node.children, fn);
    }
  }
};
