import { defaultFileType } from '/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function downloadFile(content: any, contentType = '', fileName = '') {
  const type = contentType || defaultFileType;

  const a = document.createElement('a');
  const blob = new Blob([content], { type });
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName || 'downloaded_from_octopus';
  a.click();
}
