import { DeepReadonly } from 'utility-types';

import { AuthToken, ConsoleLogger, JSONMarshallerImpl, LogLevel, WebSocketClientTransport } from '@playq/irt';
import {
  AppConfigServiceClient,
  AppsManagementServiceClient,
  ExporterServiceClient,
  JiraIntegrationServiceClient,
  SpacesServiceClient,
} from '@playq/octopus2-apps';
import { SpacesPromotionsServiceClient } from '@playq/octopus2-apps-utility';
import { MarketsServiceClient } from '@playq/octopus2-markets';
import { AdminBillingServiceClient, QueryBillingServiceClient } from '@playq/octopus2-billing';
import {
  AuthServiceClient,
  CompanyServiceClient,
  CredentialsPublicServiceClient,
  PermsServiceClient,
  UserProfileServiceClient,
  UsersServiceClient,
} from '@playq/octopus2-auth';
import { GenericFailure, CommonSuccess } from '@playq/services-shared';
import {
  AdminCompanyLookupServiceClient,
  AdminCredentialsManagementServiceClient,
  AdminPermsServiceClient,
  AdminUsersServiceClient,
  CreativeAssetsAdminServiceClient,
  ExperimentsAdminServiceClient,
  GroupsServiceClient,
  IntegrationsServiceClient,
  IntegrationsWebHookServiceClient,
  InventoryAdminServiceClient,
  LogsServiceClient,
  PackageAdminServiceClient,
  TagsServiceClient,
  WebEventsServiceClient,
} from '@playq/octopus2-admin';
import {
  AppSegmentationServiceClient,
  AppTraitsServiceClient,
  EventsMapperServiceClient,
  ExperimentsServiceClient,
  // KPIServiceClient,
  BlueprintsServiceClient,
  BlueprintRunnerServiceClient,
} from '@playq/octopus2-analytics';
import {
  AppsInventoryServiceClient,
  AppsPackagesServiceClient,
  CouponTemplateServiceClient,
  MassCouponsServiceClient,
} from '@playq/octopus2-economy';
import { AssetsFileServiceClient, FileServiceClient } from '@playq/octopus2-files';
import { LadderServiceClient, MatchmakingServiceClient, TeamTemplateServiceClient } from '@playq/octopus2-social';
import { FlowsServiceClient, GameEventsServiceClient } from '@playq/octopus2-liveops';
import { SynapseProblemServiceClient } from '@playq/octopus2-ml';
import { CopyEntityServiceClient } from '@playq/octopus2-creatives';
import { AppStatusServiceClient } from '@playq/appstatus';
import {
  BookkeperLookupServiceClient,
  ClerkSupportServiceClient,
  ClerkTeamScoreSupportServiceClient,
  ClerkUserScoreSupportServiceClient,
  ConfigurationServiceClient,
  FingerprintAppsManagementServiceClient,
  FingerprintLookupServiceClient,
  TeamsChatLookupServiceClient,
  TeamsLookupServiceClient,
  VaultLookupServiceClient,
  UserDataManagementServiceClient,
} from '@playq/octopus2-tgservices';
import { Left } from '@playq/irt';
import { ScriptsServiceClient } from '@playq/octopus2-scripts';
import { CloudFunctionsServiceClient } from '@playq/octopus2-cloud';
import { GatewayServiceClient, NotificationsTimelineClient } from '@playq/octopus-notifications';
import { AdminSupportServiceClient } from '@playq/services-beetle';
import { AudienceRequestsClusterizationServiceClient } from '@playq/octopus2-audience';
import { AudienceSupportServiceClient } from '@playq/octopus2-audience';
import { DashboardsServiceClient } from '@playq/octopus2-dashboards';
import {
  OnlineDevicesManagementServiceClient,
  OnlineDevicesSubscriptionServiceClient,
  DeviceStatus,
} from '@playq/octopus2-devices';

import { HTTPCachedTransport } from '/api/cachedTransport';
import { createTransport } from '/api/transport';
import { Context, createWebsocketTransport } from '/api/websocketTransport';
import { logSnackbarError } from '/common/snackbarService';

export class Services2 {
  readonly transport: HTTPCachedTransport;
  readonly websocketTransport: WebSocketClientTransport<Context>;

  readonly authService: AuthServiceClient;
  readonly permsService: PermsServiceClient;
  readonly usersService: UsersServiceClient;
  readonly userProfileService: UserProfileServiceClient;
  readonly companyService: CompanyServiceClient;
  readonly appsService: AppsManagementServiceClient;
  readonly marketsService: MarketsServiceClient;
  readonly spacesService: SpacesServiceClient;
  readonly spacesPromotionsService: SpacesPromotionsServiceClient;
  readonly filesService: FileServiceClient;
  readonly fingerprintLookupService: FingerprintLookupServiceClient;
  readonly logsService: LogsServiceClient;
  readonly webEventsService: WebEventsServiceClient;
  readonly integrationsService: IntegrationsServiceClient;
  readonly intergrationsWebHookService: IntegrationsWebHookServiceClient;
  readonly adminUsersService: AdminUsersServiceClient;
  readonly adminPermsService: AdminPermsServiceClient;
  readonly adminSupportService: AdminSupportServiceClient;
  readonly audienceRequestsClusterizationService: AudienceRequestsClusterizationServiceClient;
  readonly audienceSupportService: AudienceSupportServiceClient;
  readonly creativeAssetsAdminService: CreativeAssetsAdminServiceClient;
  readonly inventoryAdminService: InventoryAdminServiceClient;
  readonly packageAdminService: PackageAdminServiceClient;
  readonly experimentsAdminService: ExperimentsAdminServiceClient;
  readonly adminCompanyLookupService: AdminCompanyLookupServiceClient;
  readonly adminGroupsService: GroupsServiceClient;
  readonly appsInventoryService: AppsInventoryServiceClient;
  readonly appsPackagesService: AppsPackagesServiceClient;
  readonly massCouponsService: MassCouponsServiceClient;
  readonly targetedCouponsService: CouponTemplateServiceClient;
  readonly appTraitsService: AppTraitsServiceClient;
  readonly appEventsMapperService: EventsMapperServiceClient;
  readonly appExperimentsService: ExperimentsServiceClient;
  readonly appSegmentsService: AppSegmentationServiceClient;
  // readonly kpiService: KPIServiceClient;
  readonly appConfigService: AppConfigServiceClient;
  readonly lboardsService: LadderServiceClient;
  readonly matchesService: MatchmakingServiceClient;
  readonly teamsService: TeamTemplateServiceClient;
  readonly appGameEventsService: GameEventsServiceClient;
  readonly synapseProblemsService: SynapseProblemServiceClient;
  readonly flowsService: FlowsServiceClient;
  readonly copiesService: CopyEntityServiceClient;
  readonly appAssetsService: AssetsFileServiceClient;
  readonly configurationService: ConfigurationServiceClient;
  readonly fingerprintService: FingerprintAppsManagementServiceClient;
  readonly vaultLookupService: VaultLookupServiceClient;
  readonly playerLookupService: UserDataManagementServiceClient;
  readonly bookkeeperLookupService: BookkeperLookupServiceClient;
  readonly clerkSupportService: ClerkSupportServiceClient;
  readonly cloudFunctionsService: CloudFunctionsServiceClient;
  readonly scriptsService: ScriptsServiceClient;
  readonly notificationsTimelineService: NotificationsTimelineClient;
  private readonly gatewayService: GatewayServiceClient;
  private readonly onlineSubscriptionServiceSocket: OnlineDevicesSubscriptionServiceClient;
  readonly tagsService: TagsServiceClient;
  readonly teamsLookupService: TeamsLookupServiceClient;
  readonly teamsChatLookupService: TeamsChatLookupServiceClient;
  readonly adminBillingService: AdminBillingServiceClient;
  readonly queryBillingService: QueryBillingServiceClient;
  readonly blueprintsService: BlueprintsServiceClient;
  readonly blueprintRunnerService: BlueprintRunnerServiceClient;
  readonly blueprintRunnerServiceSocket: BlueprintRunnerServiceClient;
  readonly blueprintRunnerEphemeral: DeepReadonly<{
    sendSubscription: InstanceType<typeof BlueprintRunnerServiceClient>['subscribeForUpdates'];
    sendUnsubscription: InstanceType<typeof BlueprintRunnerServiceClient>['unsubscribe'];
  }>;
  readonly exporterService: ExporterServiceClient;
  readonly credentialsService: CredentialsPublicServiceClient;
  readonly jiraIntegrationService: JiraIntegrationServiceClient;
  readonly adminCredentialsManagementService: AdminCredentialsManagementServiceClient;
  readonly userScoreSupportService: ClerkUserScoreSupportServiceClient;
  readonly teamScoreSupportService: ClerkTeamScoreSupportServiceClient;
  readonly gatewayEphemeral: DeepReadonly<{ send: InstanceType<typeof GatewayServiceClient>['sendEphemeral'] }>;
  readonly onlineDevicesEphemeral: DeepReadonly<{
    sendSubscription: InstanceType<typeof OnlineDevicesSubscriptionServiceClient>['subscribe'];
    sendUnsubscription: InstanceType<typeof OnlineDevicesSubscriptionServiceClient>['unsubscribe'];
  }>;
  readonly appStatusService: AppStatusServiceClient;
  readonly dashboardsService: DashboardsServiceClient;
  readonly onlineDeviceService: OnlineDevicesManagementServiceClient;
  readonly onlineSubscriptionService: OnlineDevicesSubscriptionServiceClient;

  constructor() {
    const marshaller = new JSONMarshallerImpl(true);
    const logger = new ConsoleLogger(LogLevel.Warning);

    // @ts-expect-error Type 'HTTPCachedTransport | HybridClientTransport' is not assignable to type 'HTTPCachedTransport'.
    this.transport = createTransport(marshaller, logger, 3);
    this.websocketTransport = createWebsocketTransport(marshaller, logger, 3);
    this.websocketTransport.onFailure = (_service, _method, error: string | { cause?: string }) => {
      if (
        typeof error === 'object' &&
        error.cause === 'No valid credentials' &&
        this.transport.getAuthorization() !== undefined
      ) {
        this.websocketTransport.setAuthorization(this.transport.getAuthorization());
      }
    };

    this.authService = new AuthServiceClient(this.transport);
    this.permsService = new PermsServiceClient(this.transport);
    this.usersService = new UsersServiceClient(this.transport);
    this.userProfileService = new UserProfileServiceClient(this.transport);
    this.companyService = new CompanyServiceClient(this.transport);
    this.appsService = new AppsManagementServiceClient(this.transport);
    this.marketsService = new MarketsServiceClient(this.transport);
    this.spacesService = new SpacesServiceClient(this.transport);
    this.spacesPromotionsService = new SpacesPromotionsServiceClient(this.transport);
    this.filesService = new FileServiceClient(this.transport);
    this.fingerprintLookupService = new FingerprintLookupServiceClient(this.transport);
    this.logsService = new LogsServiceClient(this.transport);
    this.webEventsService = new WebEventsServiceClient(this.transport);
    this.integrationsService = new IntegrationsServiceClient(this.transport);
    this.intergrationsWebHookService = new IntegrationsWebHookServiceClient(this.transport);
    this.adminUsersService = new AdminUsersServiceClient(this.transport);
    this.adminPermsService = new AdminPermsServiceClient(this.transport);
    this.creativeAssetsAdminService = new CreativeAssetsAdminServiceClient(this.transport);
    this.inventoryAdminService = new InventoryAdminServiceClient(this.transport);
    this.packageAdminService = new PackageAdminServiceClient(this.transport);
    this.adminCompanyLookupService = new AdminCompanyLookupServiceClient(this.transport);
    this.audienceRequestsClusterizationService = new AudienceRequestsClusterizationServiceClient(this.transport);
    this.experimentsAdminService = new ExperimentsAdminServiceClient(this.transport);
    this.adminGroupsService = new GroupsServiceClient(this.transport);
    this.appsPackagesService = new AppsPackagesServiceClient(this.transport);
    this.appsInventoryService = new AppsInventoryServiceClient(this.transport);
    this.massCouponsService = new MassCouponsServiceClient(this.transport);
    this.targetedCouponsService = new CouponTemplateServiceClient(this.transport);
    this.appTraitsService = new AppTraitsServiceClient(this.transport);
    this.appEventsMapperService = new EventsMapperServiceClient(this.transport);
    this.appSegmentsService = new AppSegmentationServiceClient(this.transport);
    // this.kpiService = new KPIServiceClient(this.transport);
    this.appConfigService = new AppConfigServiceClient(this.transport);
    this.appExperimentsService = new ExperimentsServiceClient(this.transport);
    this.lboardsService = new LadderServiceClient(this.transport);
    this.matchesService = new MatchmakingServiceClient(this.transport);
    this.teamsService = new TeamTemplateServiceClient(this.transport);
    this.appGameEventsService = new GameEventsServiceClient(this.transport);
    this.synapseProblemsService = new SynapseProblemServiceClient(this.transport);
    this.flowsService = new FlowsServiceClient(this.transport);
    this.copiesService = new CopyEntityServiceClient(this.transport);
    this.appAssetsService = new AssetsFileServiceClient(this.transport);
    this.configurationService = new ConfigurationServiceClient(this.transport);
    this.fingerprintService = new FingerprintAppsManagementServiceClient(this.transport);
    this.vaultLookupService = new VaultLookupServiceClient(this.transport);
    this.playerLookupService = new UserDataManagementServiceClient(this.transport);
    this.bookkeeperLookupService = new BookkeperLookupServiceClient(this.transport);
    this.clerkSupportService = new ClerkSupportServiceClient(this.transport);
    this.cloudFunctionsService = new CloudFunctionsServiceClient(this.transport);
    this.notificationsTimelineService = new NotificationsTimelineClient(this.transport);
    this.gatewayService = new GatewayServiceClient(this.websocketTransport);
    this.tagsService = new TagsServiceClient(this.transport);
    this.teamsLookupService = new TeamsLookupServiceClient(this.transport);
    this.teamsChatLookupService = new TeamsChatLookupServiceClient(this.transport);
    this.adminBillingService = new AdminBillingServiceClient(this.transport);
    this.queryBillingService = new QueryBillingServiceClient(this.transport);
    this.adminSupportService = new AdminSupportServiceClient(this.transport);
    this.audienceSupportService = new AudienceSupportServiceClient(this.transport);
    this.scriptsService = new ScriptsServiceClient(this.transport);
    this.blueprintsService = new BlueprintsServiceClient(this.transport);
    this.blueprintRunnerService = new BlueprintRunnerServiceClient(this.transport);
    this.blueprintRunnerServiceSocket = new BlueprintRunnerServiceClient(this.websocketTransport);
    this.exporterService = new ExporterServiceClient(this.transport);
    this.credentialsService = new CredentialsPublicServiceClient(this.transport);
    this.jiraIntegrationService = new JiraIntegrationServiceClient(this.transport);
    this.adminCredentialsManagementService = new AdminCredentialsManagementServiceClient(this.transport);
    this.userScoreSupportService = new ClerkUserScoreSupportServiceClient(this.transport);
    this.teamScoreSupportService = new ClerkTeamScoreSupportServiceClient(this.transport);
    this.onlineSubscriptionServiceSocket = new OnlineDevicesSubscriptionServiceClient(this.websocketTransport);

    const isWebsocketTransportReady = () =>
      this.websocketTransport.getAuthorization() !== undefined && this.websocketTransport.isReady();

    const tryToSendEphemeral = async <R>(
      method: () => Promise<R>,
      resolveDefault: () => ReturnType<typeof method>,
      logErrorToSnackbar?: boolean
    ) => {
      if (!isWebsocketTransportReady()) {
        return resolveDefault();
      }

      return method().catch((e) => {
        console.error(e);

        if (logErrorToSnackbar) {
          logSnackbarError(e, false);
        }

        return resolveDefault();
      });
    };

    this.gatewayEphemeral = {
      send: async (...args) =>
        tryToSendEphemeral(this.gatewayService.sendEphemeral.bind(this.gatewayService, ...args), () =>
          Promise.resolve()
        ),
    };

    const onlineDevicesDefaultResolver = () =>
      Promise.resolve(new Left<GenericFailure, DeviceStatus>(new GenericFailure()));

    this.onlineDevicesEphemeral = {
      sendSubscription: async (...args) =>
        tryToSendEphemeral(
          this.onlineSubscriptionServiceSocket.subscribe.bind(this.onlineSubscriptionServiceSocket, ...args),
          onlineDevicesDefaultResolver
        ),
      sendUnsubscription: async (...args) =>
        tryToSendEphemeral(
          this.onlineSubscriptionServiceSocket.unsubscribe.bind(this.onlineSubscriptionServiceSocket, ...args),
          onlineDevicesDefaultResolver
        ),
    };

    const blueprintRunnerDefaultResolver = () =>
      Promise.resolve(new Left<GenericFailure, CommonSuccess>(new GenericFailure()));

    this.blueprintRunnerEphemeral = {
      sendSubscription: async (...args) =>
        tryToSendEphemeral(
          this.blueprintRunnerServiceSocket.subscribeForUpdates.bind(this.blueprintRunnerServiceSocket, ...args),
          blueprintRunnerDefaultResolver,
          true
        ),
      sendUnsubscription: async (...args) =>
        tryToSendEphemeral(
          this.blueprintRunnerServiceSocket.unsubscribe.bind(this.blueprintRunnerServiceSocket, ...args),
          blueprintRunnerDefaultResolver,
          true
        ),
    };

    this.appStatusService = new AppStatusServiceClient(this.transport);
    this.dashboardsService = new DashboardsServiceClient(this.transport);
    this.onlineDeviceService = new OnlineDevicesManagementServiceClient(this.transport);
    this.onlineSubscriptionService = new OnlineDevicesSubscriptionServiceClient(this.transport);
  }

  setAuthorization(access: string) {
    if (access) {
      this.transport.setAuthorization(new AuthToken(access));
      this.websocketTransport.setAuthorization(new AuthToken(access));
    }
  }

  resetAuthorization() {
    this.transport.setAuthorization(undefined);
    this.websocketTransport.setAuthorization(undefined);
  }

  clearCache() {
    if (this.transport instanceof HTTPCachedTransport) {
      this.transport.clearAll();
    }
  }
}

export const services2 = new Services2();
