import { JwtTokenUser } from '@playq/octopus2-auth';

import { baseTokenSchema, IJwtToken } from '/common/models';

const yupOptions = {
  strict: true, // only validate the input, and skip and coercion or transformation
  abortEarly: false, // return from validation methods on the first error rather than after all validations run
};

export function isValidToken(token: string): boolean {
  if (!token) {
    return false;
  }

  let validationPart: IJwtToken;

  try {
    const parts = token.split('.');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    validationPart = JSON.parse(atob(parts[1]));
  } catch (e) {
    console.error(`Can't parse token`);
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!validationPart) {
    return false;
  }

  if (!baseTokenSchema.isValidSync(validationPart, yupOptions)) {
    return false;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore unused variables
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const parsedJWT = new JwtTokenUser(validationPart);
  } catch (e) {
    console.error('Invalid JWT token', e);
    return false;
  }

  return true;
}
