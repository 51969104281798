import { Class } from '@playq/octopus-notifications';

export enum CouponsClass {
  MassCouponsModule = 'MassCouponsModule',
  CouponTemplateModule = 'CouponTemplateModule',
}

export { Class };

export type ModuleClass = Class | CouponsClass;
