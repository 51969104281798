import { logSnackbarError } from '/common/snackbarService';

export const stringifyJSON = (...params: Parameters<typeof JSON.stringify>): string | null => {
  let stringifiedJSON: string | null = null;

  try {
    stringifiedJSON = JSON.stringify(...params);
  } catch (error) {
    logSnackbarError(error);
  }

  return stringifiedJSON;
};
