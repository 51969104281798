import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

import { SelectDialog } from '/shared/SelectDialog';
import { handleEnterPress } from '/helpers';

import { IConfirmDialogProps, IConfirmProps } from './types';
import { setColor, IconStyles, TitleWrapperStyles, Button, ButtonStyles, useDialogStyles } from './styles';

const subject = new Subject<IConfirmDialogProps>();

export const confirmDialog = (props: IConfirmProps) => {
  subject.next({ ...props, open: true });
};

export const ConfirmDialogWrapper = () => {
  const [props, setProps] = useState<IConfirmDialogProps>({
    open: false,
  });
  const { type } = props;
  const classes = useDialogStyles();

  useEffect(() => {
    const sub = subject.subscribe((nextProps: IConfirmDialogProps) => {
      setProps(nextProps);
    });
    return () => sub.unsubscribe();
  }, []);

  const closeDialog = () => {
    setProps((p: IConfirmDialogProps) => ({
      ...p,
      open: false,
    }));
  };

  const closeButtonLabel = props.closeButton?.label ?? 'Close';
  const successButtonLabel = props.successButton?.label ?? 'Ok';

  const getTitle = props.title ?? 'Are you sure?';
  const getText = props.text ?? '';

  const handleCloseClick = () => {
    if (props.closeButton?.onClick) {
      props.closeButton.onClick(closeDialog);
    } else {
      closeDialog();
      if (props.onClose) {
        props.onClose();
      }
      if (props.afterClose) {
        props.afterClose();
      }
    }
  };

  const handleSuccessClick = () => {
    if (props.successButton?.onClick) {
      props.successButton.onClick(closeDialog);
    } else {
      closeDialog();
      if (props.onSuccess) {
        props.onSuccess();
      }
      if (props.afterClose) {
        props.afterClose();
      }
    }
  };

  const colorProps = setColor(type);

  return (
    <SelectDialog
      title={
        <TitleWrapperStyles custom={colorProps.custom}>
          {getTitle}
          <IconStyles custom={colorProps.custom} />
        </TitleWrapperStyles>
      }
      classes={{
        dialogHeader: classes.dialogHeader,
        dialogActions: classes.dialogActions,
        dialogContent: classes.dialogContent,
      }}
      maxWidth='md'
      fullWidth={false}
      content={getText}
      actions={
        !props.withoutActions && (
          <>
            {props.closeButton !== null && (
              <Button
                onClick={handleCloseClick}
                color={props.closeButton?.color ?? 'primary'}
                data-testid='confirm-dialog-cancel'
              >
                {closeButtonLabel}
              </Button>
            )}
            {props.successButton !== null && (
              <ButtonStyles
                {...colorProps}
                onClick={handleSuccessClick}
                color={props.successButton?.color ?? 'primary'}
                data-testid='confirm-dialog-submit'
              >
                {successButtonLabel}
              </ButtonStyles>
            )}
          </>
        )
      }
      open={props.open}
      onClose={closeDialog}
      onKeyUp={handleEnterPress(handleSuccessClick)}
      {...props.dialogProps}
    />
  );
};
