import is from 'is_js';
import { Link } from '@mui/material';

import { snackbarService } from '/common/snackbarService';
import { confirmDialog, ConfirmDialogType } from '/common/ConfirmDialog';

const { clipboard } = navigator;

/**
 * Write to clipboard
 * @param {string} text
 */
export function clipboardWrite(text: string) {
  clipboard.writeText(text).catch((err: Error) => {
    console.error('Could not copy text: ', err);
  });
}

/**
 * Read from clipboard
 */
const namespace = 'OCTOPUS';

export function clipboardRead() {
  return new Promise<string>((resolve) => {
    if ('readText' in clipboard) {
      clipboard.readText().then((content) => resolve(content));
    } else {
      if (!is.firefox()) {
        snackbarService.error('Failed to read clipboard contents: Read is not supported in your browser');
        return;
      }
      if (document.body.dataset.clipboardhelperinstalled) {
        const listener = (event: { data: { type: string; content: string } }) => {
          if (event.data.type && event.data.type === `${namespace}:CLIPBOARD_CONTENT`) {
            resolve(event.data.content);
            window.removeEventListener('message', listener);
          }
        };
        window.addEventListener('message', listener);
        window.postMessage({ type: `${namespace}:REQUEST_CLIPBOARD_CONTENT` }, '*');
      } else {
        confirmDialog({
          title: `Pasting failed!`,
          text: (
            <>
              Reading from the clipboard is not possible in Firefox browser. To fix this, install{' '}
              <Link href='https://github.com/PlayQ/tg-octopus-clipboard-extension#for-users'>this extension</Link>,
              restart your browser and try again
            </>
          ),
          type: ConfirmDialogType.Error,
          dialogProps: {
            maxWidth: 'sm',
          },
        });
      }
    }
  });
}
