export function getUrlAppName(path: string): string | undefined {
  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (!path || !path.includes('/apps') || path.includes('/apps/manage')) {
    return undefined;
  }
  const pathWithTrailingSlash = path.endsWith('/') ? path : `${path}/`;
  const parts = pathWithTrailingSlash.split('/apps/');
  if (parts.length > 1) {
    const appPath = parts.pop();
    return appPath ? appPath.split('/').shift() : undefined;
  }
}
