export enum KnownTags {
  ThumbnailKey = 'thumbnail',
  ParentKey = 'parent',
  PrimaryRevKey = 'primary',
  SizeKey = 'size',
  HeightKey = 'height',
  WidthKey = 'width',
  FormatKey = 'format',
  DurationKey = 'duration',
  LanguageKey = 'lang',
  AppKey = 'app',
}
