export const getFileBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const unsuccessfulResult = '';

    if (!(file instanceof Blob)) {
      reject(unsuccessfulResult);

      return;
    }

    const fileReader: FileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
      const result = (event.target?.result as string) || unsuccessfulResult;
      const [, dataURL]: string[] = result.split(',');

      if (!dataURL) {
        reject(unsuccessfulResult);

        return;
      }

      resolve(dataURL);
    };
  });
};
