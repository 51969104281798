import { array, object, string } from 'yup';

import { META_CLASS_KEY, UNKNOWN_ITEM_CLASS } from '/component/Economy/Inventory/Items/constants';

export const metaValidationSchema = array()
  .of(
    object().shape({
      key: string().trim().required(`Key is required!`),
      value: string().trim().required(`Value is required!`),
    })
  )
  .test(
    'unique',
    'Meta keys should be unique',
    (meta?: { key: string; value: string }[]) => !!meta && meta.length === new Set(meta.map(({ key }) => key)).size
  )
  .test(
    'no-unknown-class',
    `Previously selected ${META_CLASS_KEY} was deleted, please select another one instead.`,
    (meta?: { key: string; value: string }[]) =>
      !meta?.some(({ key, value }) => key === META_CLASS_KEY && value === UNKNOWN_ITEM_CLASS)
  );
