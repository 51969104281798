export class Mimes {
  static Image = [
    'image/jpeg',
    'image/pjpeg',
    'image/gif',
    'image/bmp',
    'image/x-windows-bmp',
    'image/png',
    'image/webp',
    'image/*',
  ];
  static Video = ['video/quicktime', 'video/mp4', 'video/webm', 'video/ogg'];
  static HTML = ['text/html'];
}
