import _ from 'lodash';

import { Node, NodeGroup, Mutation } from '@playq/octopus-common';
import { Formatter } from '@playq/irt';
import { GameEvent } from '@playq/octopus2-liveops';

import { fetchMutationRemoteEntities } from '/shared/NewTree/helpers/fetchRemoteEntities';
import { IBaseTree } from '/common/models/interfaces';
import { ConfigSplitter } from '/constants';

import { NodeErrors, NodeHelpers, NodeMetaKeys } from './node';

export type MutationsErrors = Record<string, { segmentId?: string; error: string } | undefined>;

export function createNode(children: Node[]): NodeGroup {
  const node = new NodeGroup();
  node.hidden = undefined;
  node.meta = undefined;
  node.children = children;
  node.id = 'test-id';
  node.name = 'test-name';
  return node;
}

const getFindByPathResult = (type: FindByTypes, foundNode: Node | undefined, chunks: string[]) => {
  if (foundNode === undefined) {
    return undefined;
  }
  return foundNode[type] === chunks[chunks.length - 1] ? foundNode : undefined;
};

export enum FindByTypes {
  name = 'name',
  id = 'id',
}

export class TreeHelpers {
  static findByPath<C extends IBaseTree>(
    path: string,
    tree: C,
    type: FindByTypes = FindByTypes.name,
    Forced?: new () => Node
  ): Node | undefined {
    const chunks = path.split(ConfigSplitter);

    const chunksLength = chunks.length;

    if (chunksLength === 0 || !tree.nodes.length) {
      return undefined;
    }

    const parent: string = chunksLength === 1 ? chunks[0] : chunks.splice(0, 1)[0];
    let foundNode = tree.nodes.find((n: Node) => {
      if (type === FindByTypes.id) {
        return n.id === parent;
      }
      return n.name === parent;
    });

    let nextChunk = 0;

    while (nextChunk < chunksLength && foundNode) {
      const next = chunks[nextChunk];
      if (!(foundNode instanceof NodeGroup) || !foundNode.children.length) {
        break;
      }

      switch (type) {
        case FindByTypes.name: {
          if (chunksLength > 1) {
            foundNode = NodeHelpers.findByName(next, foundNode);
          }
          break;
        }
        case FindByTypes.id: {
          foundNode = NodeHelpers.findById(next, foundNode);
          break;
        }

        default: {
          foundNode = undefined;
          break;
        }
      }

      nextChunk += 1;
    }

    if (!foundNode && Forced) {
      const nNode = new Forced();
      if (type === FindByTypes.id) {
        const id = chunks.pop() as string;
        nNode.id = id;
        nNode.name = id;
      } else {
        nNode.name = chunks.pop() as string;
      }
      return nNode;
    }

    return getFindByPathResult(type, foundNode, chunks);
  }

  private static _findById(id: string, node: Node, path = ''): { node: Node; path: string } | undefined {
    const nodePath = path ? `${path}.${node.name}` : node.name;
    if (node.id === id) {
      return { node, path: nodePath };
    }
    if (node instanceof NodeGroup) {
      let i;
      let result;
      for (i = 0; result === undefined && i < node.children.length; i++) {
        result = TreeHelpers._findById(id, node.children[i], nodePath);
      }
      return result;
    }
    return undefined;
  }

  static findById<C extends IBaseTree>(id: string, tree: C): Node | undefined {
    const rootNode = createNode(tree.nodes);
    const item = TreeHelpers._findById(id, rootNode);
    if (item) {
      return item.node;
    }
  }

  static getPathById<C extends IBaseTree>(targetId: string, tree: C): { node: Node; path: string } | undefined {
    const rootNode = createNode(tree.nodes);
    const item = TreeHelpers._findById(targetId, rootNode);
    if (item) {
      item.path = item.path.substring(item.path.indexOf('.') + 1, item.path.length);
      return item;
    }
  }

  static getPathByNode<C extends IBaseTree>(targetNode: Node, tree: C): string | undefined {
    const rootNode = createNode(tree.nodes);
    const path = TreeHelpers.getNodePath(targetNode.id, rootNode);
    if (path) {
      return path.substring(path.indexOf('.') + 1, path.length);
    }
  }

  static getNodeParent<C extends IBaseTree>(id: string, tree: C): NodeGroup | null | undefined {
    const stack: Node[] = [...tree.nodes];

    if (tree.nodes.some((n) => n.id === id)) {
      return null;
    }

    while (stack.length) {
      const currentNode = stack.pop();
      if (currentNode instanceof NodeGroup) {
        const hasChild = currentNode.children.some((child) => child.id === id);
        if (hasChild) {
          return currentNode;
        }
        stack.push(...currentNode.children);
      }
    }
    return undefined;
  }

  static getNodeAncestors<C extends IBaseTree>(id: string, tree: C): NodeGroup[] {
    const ancestors: NodeGroup[] = [];
    let currentParrent = TreeHelpers.getNodeParent(id, tree);
    while (currentParrent instanceof NodeGroup) {
      ancestors.unshift(currentParrent);
      currentParrent = TreeHelpers.getNodeParent(currentParrent.id, tree);
    }
    return ancestors;
  }

  static getNodePath(id: string, node: Node, path = ''): string | undefined {
    const nodePath = path ? `${path}.${node.name}` : node.name;
    if (node.id === id) {
      return nodePath;
    }
    if (node instanceof NodeGroup) {
      let i;
      let result;
      for (i = 0; result === undefined && i < node.children.length; i++) {
        result = TreeHelpers.getNodePath(id, node.children[i], nodePath);
      }
      return result;
    }
    return undefined;
  }

  static validate<C extends IBaseTree>(tree: C): { node: Node; message: string } | null {
    const stack: Node[] = [];
    stack.push(...tree.nodes);

    while (stack.length > 0) {
      const stackNode = stack.pop();
      if (stackNode) {
        const nodeErrorMessage = NodeHelpers.validate(stackNode);
        if (nodeErrorMessage) {
          return {
            node: stackNode,
            message: nodeErrorMessage,
          };
        }
        if (stackNode instanceof NodeGroup) {
          stackNode.children.forEach((child) => {
            stack.push(child);
          });
        }
      }
    }

    return null;
  }

  static validateMutations<C extends IBaseTree>(tree: C, rootGroupName?: string): MutationsErrors {
    const errors: MutationsErrors = {};

    for (const mutation of tree.mutations) {
      if (!mutation.name) {
        errors[mutation.id] = {
          error: 'Invalid name',
        };
      }

      if (!mutation.segments.length) {
        errors[mutation.id] = {
          error: 'Not enough segments',
        };
      }
      for (const segment of mutation.segments) {
        if (!segment.name) {
          errors[mutation.id] = {
            segmentId: segment.id,
            error: 'Invalid segment name',
          };
        }

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!segment.segment.raw || !segment.segment.expression) {
          errors[mutation.id] = {
            segmentId: segment.id,
            error: 'Invalid segment expression',
          };
        }

        const notFoundSegmentNodes = segment.values.some((value) => !TreeHelpers.findById(value.node, tree));
        if (notFoundSegmentNodes) {
          errors[mutation.id] = {
            segmentId: segment.id,
            error: 'Mutation variable not found',
          };
        }
      }
      if (!TreeHelpers.isMutatedNodeExist(tree, mutation, rootGroupName)) {
        errors[mutation.id] = {
          error: 'Mutated node does not exist',
        };
      }
    }
    return errors;
  }
  static async validateMutationsRemoteEntities<C extends IBaseTree>(
    tree: C,
    rootGroupName?: string,
    fingerpintId?: string
  ): Promise<MutationsErrors> {
    const errors: MutationsErrors = {};
    const remoteEntities = await fetchMutationRemoteEntities(tree.mutations, fingerpintId);
    Array.from(remoteEntities.keys()).forEach((key) => {
      const val = remoteEntities.get(key);
      if (val?.error) {
        const [mutationId, segmentId] = key.split('.');
        errors[mutationId] = {
          segmentId,
          error: val?.error,
        };
      }
    });

    return {
      ...TreeHelpers.validateMutations(tree, rootGroupName),
      ...errors,
    };
  }

  static mergePreviousRemoteErrorsWithPlainErrors(
    mutations: Mutation[],
    previousErrors: MutationsErrors,
    nextErrors: MutationsErrors
  ): MutationsErrors {
    const mutationIds = new Set(mutations.map((mutation) => mutation.id));

    const onlyPreviousRemoteEntityErrors = _.pickBy(
      previousErrors,
      (value) => value?.segmentId && value?.error === NodeErrors.failedToLoadEntity
    );
    const merged = _.merge(onlyPreviousRemoteEntityErrors, nextErrors);
    const filteredErrors = _.pickBy(merged, (_val, key) => mutationIds.has(key));
    return filteredErrors;
  }

  static isMutatedNodeExist<C extends IBaseTree>(tree: C, mutation: Mutation, rootGroupName?: string): boolean {
    const result = TreeHelpers.getPathById(mutation.node, tree);
    if (mutation.path === 'content' && tree instanceof GameEvent) {
      // FIXME
      return true;
    }
    if (result) {
      const path = rootGroupName ? `${rootGroupName}.${result.path}` : result.path;
      return path === mutation.path;
    }
    return false;
  }

  static hideNodes<C extends IBaseTree>(gameEvent: C, hidden: string[]) {
    hidden.forEach((nodePath: string) => {
      const foundNode = TreeHelpers.findByPath(nodePath, gameEvent);
      if (foundNode) {
        foundNode.hidden = true;
      }
    });
  }

  static emptyBaseTree(): IBaseTree {
    const now = new Date();
    return {
      updatedAt: now,
      updatedAtAsString: Formatter.writeDate(now),
      mutations: [],
      nodes: [],
      version: 1,
      serialize() {
        return {
          nodes: [],
          mutations: [],
          version: 1,
          updatedAt: Formatter.writeDate(now),
        };
      },
    };
  }

  static replaceSameNodeIDs<C extends IBaseTree>(tree: C) {
    const ids: string[] = [];

    NodeHelpers.traverse(tree.nodes, (_parent, node) => {
      if (ids.includes(node.id)) {
        let newID = NodeHelpers.randomNodeID();
        while (ids.includes(newID)) {
          newID = NodeHelpers.randomNodeID();
        }
        node.id = newID;
      }
      ids.push(node.id);
    });
  }

  static removeOriginalCopiedMeta<C extends IBaseTree>(tree: C) {
    NodeHelpers.traverse(tree.nodes, (_parent, node) => {
      if (NodeHelpers.getMeta(node, NodeMetaKeys.originalCopiedNode)) {
        NodeHelpers.deleteMeta(node, NodeMetaKeys.originalCopiedNode);
      }
    });
  }

  static copyBaseTree(tree: IBaseTree): IBaseTree {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const TreeConstructor = Object.getPrototypeOf(tree).constructor;
    const serialized = tree.serialize();
    if (!serialized.version) {
      serialized.version = 0;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return new TreeConstructor(JSON.parse(JSON.stringify(serialized))) as IBaseTree;
  }
}
