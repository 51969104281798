import { DateTime } from 'luxon';

export function formatDate(date: Date | string, format = 'EEE DDD t'): string {
  const luxon = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return luxon.toLocal().toFormat(format);
}

export function formatISODate(date: Date | string): string {
  const luxon = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date, { setZone: true });
  return luxon.toISO() ?? '';
}

export function formatUTCDate(date: Date | string, format = 'EEE DDD t'): string {
  const luxon = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
  return luxon.toUTC().toFormat(format);
}
