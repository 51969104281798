/* eslint-disable prefer-spread */
import { Observable, Subject } from 'rxjs';
import { SnackbarContentCallback } from 'notistack';
import _ from 'lodash';

import { GenericFailure } from '@playq/services-shared';

import { getErrorMessage } from '/helpers/getErrorMessage';

import { ISnackbarProps } from './SnackBar';

class SnackbarService {
  private snackbarSubject = new Subject<ISnackbarProps>();

  success<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'success', persist });
  }

  info<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'info', persist });
  }

  warning<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'warning', persist });
  }

  error<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'error', persist });
  }

  genericFailure<A>(err: GenericFailure | Error | string, action?: A, persist?: boolean) {
    if (typeof err === 'string') {
      this.open({ msg: err, action, type: 'error', persist });
      return;
    }
    this.open({ msg: getErrorMessage(err) || 'Network error', action, type: 'error', persist });
  }

  default<A>(msg: string, action?: A, persist?: boolean) {
    this.open({ msg, action, type: 'default', persist });
  }

  custom(content: SnackbarContentCallback) {
    this.open({ content });
  }

  private open(props: ISnackbarProps) {
    this.snackbarSubject.next(props);
  }

  get snackbars$(): Observable<ISnackbarProps> {
    return this.snackbarSubject.asObservable();
  }
}

export const snackbarService = new SnackbarService();
export const CUSTOM_SNACKBAR = 'Custom snackbar';

export function logSnackbarError(error: unknown, logToConsole = true) {
  const isErrorObj = (err: unknown): err is { message: string } =>
    _.isPlainObject(err) && typeof (err as Error).message === 'string';

  if (isErrorObj(error)) {
    snackbarService.error(error.message);
  } else if (typeof error === 'string') {
    snackbarService.error(error);
  } else {
    snackbarService.error('An error of unknown type was thrown, see details in a console');
    console.error(error);

    return;
  }

  if (logToConsole) {
    console.error(error);
  }
}
