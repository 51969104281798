import { FileNameSplitter } from '/constants/file';

export function parseFileName(fileName: string): [string, string | undefined] {
  const parts = fileName.split(FileNameSplitter);
  const ext = parts.pop();
  const name = parts.join(FileNameSplitter);

  if (fileName === ext) {
    return [ext, ''];
  }

  if (!ext) {
    return [name, ''];
  }

  return [name.replace(`${FileNameSplitter}${ext}`, ''), ext];
}
