import { IBaseTableClasses } from '/shared/Table';

type ObjectRecord = Record<string, string | undefined>;

export function mergeClasses<ResultType extends ObjectRecord = IBaseTableClasses>(
  a: ObjectRecord,
  b: ObjectRecord
): ResultType {
  const res = { ...a };
  Object.keys(b).forEach((key: string) => {
    if (res[key]) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      res[key] += ` ${b[key]}`;
    } else {
      res[key] = b[key];
    }
  });
  return res as ResultType;
}
