export interface IEntityDefinition<E> {
  selectID: IDSelector<E>;
  sortComparer?: IComparer<E>;
  ttl?: number;
}

export type Dictionary<E> = Record<string, E>;

export interface ICollectionState<E> {
  ids: string[];
  entities: Dictionary<E>;
  updatedAt: Dictionary<number>;
  lastUpdate: number;
}

export type IDSelector<E> = (entity: E) => string;
export type IComparer<E> = (a: E, b: E) => number;

export function getInitialCollectionState<E>(): ICollectionState<E> {
  return {
    ids: [],
    entities: {},
    updatedAt: {},
    lastUpdate: 0,
  };
}

export interface ICollectionAdapter<E, C> {
  addOne(entity: E, state: C): C;

  addOnlyOne(entity: E, state: C): C;

  addMany(entities: E[], state: C): C;

  addAll(entities: E[], state: C): C;

  removeOneByKey(key: string, state: C): C;

  removeOne(entity: E, state: C): C;

  removeManyByKey(keys: string[], state: C): C;

  removeAll(state: C): C;

  updateOne(update: E, state: C): C;

  updateMany(updates: E[], state: C): C;

  upsertOne(entity: E, state: C): C;

  upsertMany(entities: E[], state: C): C;
}

export interface ICollectionSelectors<E, C> {
  selectIDs: (state: C) => string[];
  selectByID: (id: string, state: C) => E | undefined;
  selectedUpdateByID: (id: string, state: C) => number | undefined;
  selectMany: (ids: string[], state: C) => E[];
  selectAll: (state: C) => E[];
  selectCount: (state: C) => number;
  isActual: (state: C) => boolean;
}

export interface IAdapter<E, C extends ICollectionState<E> = ICollectionState<E>>
  extends ICollectionAdapter<E, C>,
    ICollectionSelectors<E, C>,
    IEntityDefinition<E> {
  getInitialState(): ICollectionState<E>;
}

export function createAdapter<E, C extends ICollectionState<E> = ICollectionState<E>>(
  definition: IEntityDefinition<E>
): IAdapter<E, C> {
  return {
    ...definition,
    selectIDs: (state: C): string[] => state.ids,
    selectByID: (id: string, state: ICollectionState<E>): E | undefined => {
      if (!(id in state.entities)) {
        return undefined;
      }
      return state.entities[id];
    },
    selectedUpdateByID: (id: string, state: ICollectionState<E>) => {
      if (!(id in state.updatedAt)) {
        return undefined;
      }
      return state.updatedAt[id];
    },
    selectMany: (ids: string[], state: ICollectionState<E>): E[] => {
      return ids.reduce((acc: E[], next: string) => {
        const entity = next in state.entities;
        if (entity) {
          acc.push(state.entities[next]);
        }
        return acc;
      }, []);
    },
    selectAll: (state: ICollectionState<E>): E[] => Object.values(state.entities).sort(definition.sortComparer),
    selectCount: (state: ICollectionState<E>): number => state.ids.length,
    getInitialState: (): ICollectionState<E> => getInitialCollectionState<E>(),
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    isActual: (state: ICollectionState<E>) => (definition.ttl ? Date.now() - state.lastUpdate < definition.ttl : true),

    // Adds one element if doesn't exist
    addOne: (entity: E, state: C): C => {
      const id = definition.selectID(entity);

      if (id in state.entities) {
        return state;
      }

      const now = Date.now();
      return {
        ...state,
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [id]: entity,
        },
        updatedAt: {
          ...state.updatedAt,
          [id]: now,
        },
        lastUpdate: now,
      };
    },
    addOnlyOne: (entity: E, state: C): C => {
      const id = definition.selectID(entity);

      if (id in state.entities) {
        return state;
      }

      const now = Date.now();
      return {
        ...state,
        ids: [id],
        entities: {
          [id]: entity,
        },
        updatedAt: {
          [id]: now,
        },
        lastUpdate: now,
      };
    },

    // Adds many, which don't exist
    addMany: (entities: E[], state: C): C => {
      const newEntities = { ...state.entities };
      const newUpdatedAt = { ...state.updatedAt };
      const newIDs = state.ids.slice();
      const now = Date.now();

      entities.forEach((e: E) => {
        const id = definition.selectID(e);
        if (!(id in newEntities)) {
          newIDs.push(id);
          newEntities[id] = e;
          newUpdatedAt[id] = now;
        }
      });

      return {
        ...state,
        ids: newIDs,
        entities: newEntities,
        updatedAt: newUpdatedAt,
        lastUpdate: now,
      };
    },

    // Adds all elements ignoring existence
    addAll: (entities: E[], state: C): C => {
      const newIds: string[] = [];
      const newEntities: Dictionary<E> = {};
      const newUpdatedAt: Dictionary<number> = {};
      const now = Date.now();

      entities.forEach((e: E) => {
        const id = definition.selectID(e);
        newIds.push(id);
        newEntities[id] = e;
        newUpdatedAt[id] = now;
      });

      return {
        ...state,
        ids: newIds,
        entities: newEntities,
        lastUpdate: now,
      };
    },

    // Removes one if exists by key
    removeOneByKey: (key: string, state: C): C => {
      const index = state.ids.indexOf(key);
      if (index < 0) {
        return state;
      }

      const ids = state.ids.slice();
      ids.splice(index, 1);

      const entities = { ...state.entities };
      delete entities[key];

      const update = { ...state.updatedAt };
      delete update[key];

      return {
        ...state,
        ids,
        entities,
        updatedAt: update,
        lastUpdate: Date.now(),
      };
    },

    // Removes one if exists
    removeOne: (entity: E, state: C): C => {
      const id = definition.selectID(entity);
      const index = state.ids.findIndex((i) => i === id);
      if (index < 0) {
        return state;
      }

      const ids = state.ids.slice();
      ids.splice(index, 1);

      const entities = { ...state.entities };
      delete entities[id];

      const update = { ...state.updatedAt };
      delete update[id];

      return {
        ...state,
        ids,
        entities,
        updatedAt: update,
      };
    },

    // Removes many if exist
    removeManyByKey: (keys: string[], state: C): C => {
      const ids = state.ids.slice();
      const entities = { ...state.entities };
      const updatedAt = { ...state.updatedAt };

      keys.forEach((k: string) => {
        if (!(k in state.entities)) {
          return;
        }

        ids.splice(ids.indexOf(k));
        delete entities[k];
        delete updatedAt[k];
      });

      return {
        ...state,
        ids,
        entities,
        updatedAt,
        lastUpdate: Date.now(),
      };
    },

    // Empties the collection
    removeAll: (state: C): C => {
      return {
        ...state,
        ...getInitialCollectionState<E>(),
      };
    },

    updateOne: (update: E, state: C): C => {
      const id = definition.selectID(update);
      const index = state.ids.indexOf(id);
      if (index < 0) {
        return state;
      }

      const now = Date.now();
      return {
        ...state,
        ids: state.ids,
        entities: {
          ...state.entities,
          [id]: update,
        },
        updatedAt: {
          ...state.updatedAt,
          [id]: now,
        },
        lastUpdate: now,
      };
    },

    updateMany: (updates: E[], state: C): C => {
      const entities = { ...state.entities };
      const updatedAt = { ...state.updatedAt };
      const now = Date.now();

      updates.forEach((e: E) => {
        const id = definition.selectID(e);
        if (!(id in entities)) {
          return;
        }
        entities[id] = e;
        updatedAt[id] = now;
      });

      return {
        ...state,
        entities,
        updatedAt,
        ids: state.ids,
        lastUpdate: now,
      };
    },

    upsertOne: (upsert: E, state: C): C => {
      const id = definition.selectID(upsert);
      const now = Date.now();

      return {
        ...state,
        ids: id in state.entities ? state.ids : [...state.ids, id],
        entities: {
          ...state.entities,
          [id]: upsert,
        },
        updatedAt: {
          ...state.updatedAt,
          [id]: now,
        },
        lastUpdate: now,
      };
    },

    upsertMany: (upserts: E[], state: C): C => {
      const ids = state.ids.slice();
      const entities = { ...state.entities };
      const updatedAt = { ...state.updatedAt };
      const now = Date.now();

      upserts.forEach((e: E) => {
        const id = definition.selectID(e);
        if (id in entities) {
          entities[id] = e;
          updatedAt[id] = now;
        } else {
          entities[id] = e;
          updatedAt[id] = now;
          ids.push(id);
        }
      });

      return {
        ...state,
        ids,
        entities,
        updatedAt,
        lastUpdate: now,
      };
    },
  };
}
